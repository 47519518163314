<p class="top-label">
  {{ right?.filePane?.fileTitle ?? '' | translate }}
</p>

<app-file-picker
  [platform]="platform"
  [dataCyTag]="dataCyTag"
  [error]="right?.error"
  [file]="right?.value"
  [showSpinner]="right?.filePane?.showSpinner"
  (fileAddEvent)="onAddFile()"
  (fileDeleteEvent)="onDeleteFile()"
  (updateFileEvent)="updateFile($event)"
  (processFileEvent)="processFile($event)"
>
</app-file-picker>
<p
  *ngIf="right?.filePane?.showExtraInfo"
  (click)="requestInfo()"
  class="template item-subtitle-regular go-green"
>
  <ion-icon name="download-outline" data-cy="template-download-icon"></ion-icon>
  {{ right?.filePane?.infoLabel | translate }}
</p>
