import { Component } from '@angular/core';
import { SelectableCountableItem } from '../../../selectable-countable-item/selectable-countable-item.component';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';

@Component({
  selector: 'app-single-select-countable-item',
  templateUrl: './single-select-countable-item.component.html',
  styleUrls: [
    './single-select-countable-item.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class SingleSelectCountableItemComponent extends SearchablePaneComponent {
  constructor() {
    super();
  }

  itemSelected(item: SelectableCountableItem) {
    if (this.right?.selectedItem === item) {
      this.right.selectedItem = undefined;
    } else {
      this.right.selectedItem = item;
    }
    this.right?.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      this.right?.selectedItem?.title,
    );
    this.checkValidity.emit();
  }
}
