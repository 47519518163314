import {
  InventoryPageData,
  InventoryViewModel,
} from '@inventory/models/inventory-view.model';
import {
  combineMapAndArrayForNonUSFProducts,
  convertToInventoryViewModel,
  getInventoryCardIds,
} from '@inventory/selectors/helpers/inventory-view-model-helper';
import { createSelector } from '@ngrx/store';
import { PlatformEnum } from '@panamax/app-state';
import { Product } from '@shared/models/product.model';
import { productSelector } from '@shared/selectors/product.selectors';
import {
  IInventoryState,
  InventoryGroupHeader,
  InventoryOptions,
  SELECTED_GROUP_DEFAULT,
  selectInventoryById,
  selectInventoryOptions,
  selectInventoryState,
  selectInventoriesWithCounts,
  selectInventoryValidators,
  selectAllInventories,
  selectAllInventoriesSearchKey,
} from '@usf/ngrx-inventory';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import { InventoryWorksheetMode } from '../constants/inventory-constants';
import { InventoryProductsToLoad } from '../models/inventory-products-to-load.model';
import {
  calcAllInventoriesItemHeights,
  calcInventoryWorksheetItemHeights,
  calcRecentInventoriesItemHeights,
} from './helpers/inventory-view-model-scroll-helper';
import {
  convertInventoriesSort,
  sortInventory,
} from './helpers/inventory-view-model-sort-filter-helper';
import { InventoryStatus } from '@usf/inventory-types';
import { NonUsfProductViewModelSelectors } from '@inventory/pages/manage-non-usf-products/selectors/non-usf-selectors';
import { transformNonUsfProductToProduct } from '@inventory/pages/manage-non-usf-products/transformers/non-usf-products-transformers';
import { InventoryMigrationViewModel } from '@inventory/models/inventory-migration-view-model.model';
import {
  areDatesEqual,
  grabDateFromStringDateType,
} from '@shared/helpers/calendar.helpers';
import { GlPim, selectAllGlCodes, selectGlPimDictionary } from '@usf/ngrx-gl';
import { GeneralLedger } from '@usf/product-types';
import { Dictionary } from '@ngrx/entity';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types/customer';

export const inventoryStateIsLoaded = createSelector(
  selectInventoryState,
  (iInventoryState: IInventoryState): boolean => iInventoryState.loaded,
);

export const selectInventories = (keyword?: string) =>
  createSelector(
    selectInventoryState,
    (inventoryState: IInventoryState) =>
      Object.values(inventoryState.entities)
        .filter(inventory => filterInventory(inventory, keyword))
        .sort(
          (a, b) =>
            new Date(b?.ecomUpdateDtm).getTime() -
            new Date(a?.ecomUpdateDtm).getTime(),
        ) ?? [],
  );

export const selectInventoriesForAllInventoriesPage = () =>
  createSelector(
    selectInventoryState,
    selectAllInventoriesSearchKey(),
    (inventoryState: IInventoryState, keyword: string) =>
      Object.values(inventoryState.entities)
        .filter(inventory => filterInventory(inventory, keyword))
        .sort(
          (a, b) =>
            new Date(b?.ecomUpdateDtm).getTime() -
            new Date(a?.ecomUpdateDtm).getTime(),
        ) ?? [],
  );

export const filterInventory = (inventory: InventoryState, keyword: string) => {
  if (!keyword) {
    return true;
  }
  const inventoryPotentialCheck = [];
  const keyWords = [];
  inventoryPotentialCheck.push(
    new Date(inventory?.inventoryDate)?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
  );

  inventory?.inventoryName
    ?.toUpperCase()
    .split('-')
    .forEach(nameSegment =>
      inventoryPotentialCheck.push(...nameSegment.split(' ')),
    );

  (keyword || '')
    ?.toUpperCase()
    .split('-')
    .forEach(nameSegment => keyWords.push(...nameSegment.split(' ')));

  return keyWords.every(valueCheck => {
    return !!inventoryPotentialCheck.find(key => {
      return !!key.includes(valueCheck);
    });
  });
};

export const selectGlCodes = () =>
  createSelector(selectAllGlCodes(), (glCodes: GeneralLedger[]) => glCodes);

export const selectInventoriesForCostReportModal = () =>
  createSelector(selectInventoryState, (inventoryState: IInventoryState) => {
    const inventories: InventoryState[] =
      Object.values(inventoryState.entities) ?? [];
    const completedInventories: InventoryState[] = inventories.filter(
      inventory => inventory.status === InventoryStatus.COMPLETED,
    );
    const sortedInventories: InventoryState[] = completedInventories.sort(
      (a, b) =>
        new Date(b?.inventoryDate) > new Date(a?.inventoryDate) ? 1 : -1,
    );
    return sortedInventories;
  });

export const selectInventoriesForCostComparisonReportModal = () =>
  createSelector(
    selectInventoriesWithCounts,
    (inventoryStates: InventoryState[]) => {
      const completedInventories: InventoryState[] = inventoryStates.filter(
        inventory => inventory.status === InventoryStatus.COMPLETED,
      );
      const sortedInventories: InventoryState[] = completedInventories.sort(
        (a, b) =>
          a?.inventoryName?.toLowerCase() > b?.inventoryName?.toLowerCase()
            ? 1
            : -1,
      );
      return sortedInventories;
    },
  );

export const getInventoryProductsToLoad = (id: string) =>
  createSelector(
    selectInventoryById(id),
    inventoryStateIsLoaded,
    (
      inventory: InventoryState,
      inventoryStateIsLoaded: boolean,
    ): InventoryProductsToLoad => {
      let productNumbers: number[] = [];
      if (inventory) {
        if (inventory.groups) {
          inventory.groups.forEach(group => {
            if (group.groupItems) {
              group.groupItems.forEach(item => {
                if (productNumbers.indexOf(item.itemNumber) === -1) {
                  if (!item.nonUsfFlag) {
                    productNumbers.push(item.itemNumber);
                  }
                }
              });
            }
          });
        }
      }
      return {
        inventory,
        productNumbers,
        inventoryStateIsLoaded,
      } as InventoryProductsToLoad;
    },
  );

// CREATE INVENTORY PAGE SELECTORS
export const selectPastInventoriesViewModels = (keyword?: string) =>
  createSelector(
    selectInventories(keyword),
    selectInventoryOptions(),
    productSelector,
    new NonUsfProductViewModelSelectors().getAllNonUSFProductsAndConvertToFakeProducts(),
    selectGlPimDictionary,
    (
      inventories: InventoryState[],
      inventoryOptions: InventoryOptions,
      products: Map<number, Product>,
      nonUSFProducts: Product[],
      glPimDictionary: Dictionary<GlPim>,
    ) => {
      const convertedInventories: InventoryViewModel[] = [];
      const prodMap = combineMapAndArrayForNonUSFProducts(
        products,
        nonUSFProducts,
      );

      inventories.forEach(inventory => {
        if (inventory && inventoryOptions) {
          convertedInventories.push(
            convertToInventoryViewModel(
              inventory,
              inventoryOptions,
              false,
              prodMap,
              glPimDictionary,
              {
                isNotWorksheet: true,
              },
            ),
          );
        }
      });

      return convertedInventories;
    },
  );

// INVENTORY LANDING PAGE (RECENT INVENTORIES) SELECTORS
export const selectRecentInventories = () =>
  createSelector(
    selectInventories(),
    (inventories: InventoryState[]) => inventories?.slice(0, 15) ?? [],
  );

export const selectRecentInventoriesSort = () =>
  createSelector(
    selectInventoryState,
    (inventoryState: IInventoryState) => inventoryState.recentInventoriesSort,
  );

export const selectRecentInventoriesViewModels = () =>
  createSelector(
    selectRecentInventories(),
    selectInventoryOptions(),
    productSelector,
    selectGlPimDictionary,
    new NonUsfProductViewModelSelectors().getAllNonUSFProductsAndConvertToFakeProducts(),
    (
      recentInventories: InventoryState[],
      inventoryOptions: InventoryOptions,
      products: Map<number, Product>,
      glPimDictionary: Dictionary<GlPim>,
      nonUSFProducts: Product[],
    ) => {
      const convertedInventories: InventoryViewModel[] = [];
      const prodMap = combineMapAndArrayForNonUSFProducts(
        products,
        nonUSFProducts,
      );

      recentInventories.forEach(inventory => {
        if (inventory && inventoryOptions) {
          convertedInventories.push(
            convertToInventoryViewModel(
              inventory,
              inventoryOptions,
              false,
              prodMap,
              glPimDictionary,
              {
                isNotWorksheet: true,
              },
            ),
          );
        }
      });

      return convertedInventories;
    },
  );

export const recentInventoriesView = (platform: PlatformEnum) =>
  createSelector(
    inventoryStateIsLoaded,
    selectRecentInventoriesSort(),
    selectRecentInventoriesViewModels(),
    (
      inventoryStateIsLoaded,
      inventoriesSort,
      inventories: InventoryViewModel[],
    ) => {
      const sortedInventories: InventoryViewModel[] = sortInventory(
        inventoriesSort,
        'inventories',
        inventories,
      );

      const convertedRecentInventoriesSort = convertInventoriesSort(
        platform,
        inventoriesSort,
      );

      const itemHeights: number[] = calcRecentInventoriesItemHeights(
        sortedInventories,
        platform,
      );

      return {
        isInventoryDataLoaded: inventoryStateIsLoaded,
        inventoriesSort: convertedRecentInventoriesSort,
        inventories: sortedInventories,
        itemHeights: itemHeights,
      } as InventoryPageData;
    },
  );

// ALL INVENTORIES PAGE SELECTORS
export const selectAllInventoriesSort = () =>
  createSelector(
    selectInventoryState,
    (inventoryState: IInventoryState) => inventoryState.allInventoriesSort,
  );

export const selectAllInventoriesViewModels = () =>
  createSelector(
    selectInventoriesForAllInventoriesPage(),
    selectInventoryOptions(),
    productSelector,
    selectGlPimDictionary,
    new NonUsfProductViewModelSelectors().getAllNonUSFProductsAndConvertToFakeProducts(),
    (
      inventories: InventoryState[],
      inventoryOptions: InventoryOptions,
      products: Map<number, Product>,
      glPimDictionary: Dictionary<GlPim>,
      nonUSFProducts: Product[],
    ) => {
      const convertedInventories: InventoryViewModel[] = [];
      const prodMap = combineMapAndArrayForNonUSFProducts(
        products,
        nonUSFProducts,
      );

      inventories?.forEach(inventory => {
        if (inventory && inventoryOptions) {
          if (
            (inventoryOptions.showCompleted &&
              inventory.status === InventoryStatus.COMPLETED) ||
            !inventoryOptions.showCompleted
          ) {
            convertedInventories.push(
              convertToInventoryViewModel(
                inventory,
                inventoryOptions,
                false,
                prodMap,
                glPimDictionary,
                {
                  isNotWorksheet: true,
                },
              ),
            );
          }
        }
      });
      return convertedInventories;
    },
  );

export const allInventoriesView = (platform: PlatformEnum) =>
  createSelector(
    inventoryStateIsLoaded,
    selectAllInventoriesSort(),
    selectAllInventoriesViewModels(),
    (
      inventoryStateIsLoaded,
      inventoriesSort,
      inventories: InventoryViewModel[],
    ) => {
      const sortedInventories: InventoryViewModel[] = sortInventory(
        inventoriesSort,
        'inventories',
        inventories,
      );

      const convertedAllInventoriesSort = convertInventoriesSort(
        platform,
        inventoriesSort,
      );

      const itemHeights: number[] = calcAllInventoriesItemHeights(
        sortedInventories,
        platform,
      );

      return {
        isInventoryDataLoaded: inventoryStateIsLoaded,
        inventoriesSort: convertedAllInventoriesSort,
        inventories: sortedInventories,
        itemHeights,
      } as InventoryPageData;
    },
  );

// INVENTORY WORKSHEET PAGE SELECTORS
export const selectInventoryWorksheetViewModel = (
  platform: PlatformEnum,
  id: string,
) => {
  return createSelector(
    selectInventoryById(id),
    selectInventoryOptions(),
    productSelector,
    selectGlPimDictionary,
    new NonUsfProductViewModelSelectors().getAllNonUSFProductsAndConvertToFakeProducts(),
    (
      inventory,
      inventoryOptions,
      products: Map<number, Product>,
      glPimDictionary: Dictionary<GlPim>,
      nonUSFProducts: Product[],
    ) => {
      const deletedProducts = [];
      if (inventory?.groups)
        inventory.groups.forEach(group => {
          if (group.groupItems)
            group.groupItems.forEach(item => {
              if (
                !products.has(item.itemNumber) &&
                !nonUSFProducts.some(
                  non => non.productNumber === item.itemNumber,
                )
              ) {
                deletedProducts.push(
                  transformNonUsfProductToProduct({
                    divisionNumber: inventory.divisionNumber,
                    customerNumber: inventory.customerNumber,
                    productDescLong: '',
                    productNumber: item.itemNumber,
                    isSelected: false,
                    vendorName: '',
                  }),
                );
              }
            });
        });

      const prodMap = combineMapAndArrayForNonUSFProducts(products, [
        ...nonUSFProducts,
        ...deletedProducts,
      ]);
      if (inventory && inventoryOptions) {
        return convertToInventoryViewModel(
          inventory,
          inventoryOptions,
          true,
          prodMap,
          glPimDictionary,
          {
            isCount:
              inventoryOptions.inventoryWorksheetMode ===
              InventoryWorksheetMode.count
                ? true
                : false,
            platform,
          },
        );
      }
    },
  );
};

export const selectMultipleInventoryWorksheetViewModels = (
  platform: PlatformEnum,
  inventories: InventoryState[],
  ignoreSearch: boolean,
) => {
  return createSelector(
    selectInventoryOptions(),
    productSelector,
    selectGlPimDictionary,
    new NonUsfProductViewModelSelectors().getAllNonUSFProductsAndConvertToFakeProducts(),
    (
      inventoryOptions,
      products: Map<number, Product>,
      glPimDictionary: Dictionary<GlPim>,
      nonUSFProducts: Product[],
    ): InventoryViewModel[] => {
      const inventoryViewModels: InventoryViewModel[] = [];
      for (const inventory of inventories) {
        const deletedProducts = [];
        if (inventory.groups)
          inventory.groups.forEach(group => {
            if (group.groupItems)
              group.groupItems.forEach(item => {
                if (
                  !products.has(item.itemNumber) &&
                  !nonUSFProducts.some(
                    non => non.productNumber === item.itemNumber,
                  )
                ) {
                  deletedProducts.push(
                    transformNonUsfProductToProduct({
                      divisionNumber: inventory.divisionNumber,
                      customerNumber: inventory.customerNumber,
                      productDescLong: '',
                      productNumber: item.itemNumber,
                      isSelected: false,
                      vendorName: '',
                    }),
                  );
                }
              });
          });

        const prodMap = combineMapAndArrayForNonUSFProducts(products, [
          ...nonUSFProducts,
          ...deletedProducts,
        ]);
        if (inventory && inventoryOptions) {
          const inventoryViewModel = convertToInventoryViewModel(
            inventory,
            inventoryOptions,
            true,
            prodMap,
            glPimDictionary,
            {
              isCount:
                inventoryOptions.inventoryWorksheetMode ===
                InventoryWorksheetMode.count
                  ? true
                  : false,
              platform,
            },
            ignoreSearch,
          );
          inventoryViewModels.push(inventoryViewModel);
        }
      }

      return inventoryViewModels;
    },
  );
};

export const inventoryWorksheetView = (platform: PlatformEnum, id: string) =>
  createSelector(
    selectInventoryWorksheetViewModel(platform, id),
    (inventory: InventoryViewModel): InventoryViewModel => {
      if (inventory?.items) {
        if (
          inventory?.inventoryOptions?.selectedGroup.groupName.toLowerCase() !==
          SELECTED_GROUP_DEFAULT.groupName.toLowerCase()
        ) {
          inventory.items = inventory?.items.filter(
            item =>
              item.groupName ===
              inventory?.inventoryOptions?.selectedGroup.groupName,
          );
        } else {
          const selectedGroup: InventoryGroupHeader = {
            groupName: 'All',
            numProducts: inventory.numProducts,
          };
          inventory.inventoryOptions = {
            ...inventory.inventoryOptions,
            selectedGroup,
          };
        }
        inventory.itemHeights = calcInventoryWorksheetItemHeights(
          inventory.items,
          platform,
          inventory.inventoryOptions.inventoryWorksheetMode ===
            InventoryWorksheetMode.count
            ? true
            : false,
        );
        inventory.inventoryCardIds = getInventoryCardIds(
          inventory.items,
          inventory.inventoryOptions.inventoryWorksheetMode ===
            InventoryWorksheetMode.edit,
          inventory.inventoryId,
        );
      }
      return inventory;
    },
  );

export const selectCustomerMigration = () =>
  createSelector(selectInventoryState, (inventoryState: IInventoryState) => {
    let inventoryMigrationViewModel: InventoryMigrationViewModel = {
      ...inventoryState.customerMigrationStatus,
      withinThreeDays: dateIsWithinThreeDays(
        inventoryState.customerMigrationStatus.updateDtm,
      ),
    };
    return inventoryMigrationViewModel;
  });

export const selectCustomerHasLegacyData = () =>
  createSelector(selectInventoryState, (inventoryState: IInventoryState) => {
    return inventoryState.customerMigrationStatus.hasR2Inventory;
  });

export const dateIsWithinThreeDays = (
  inventoryMigrationInitiationDate: string | Date,
) => {
  const currentDate = new Date();
  const threeDaysAgo = new Date(
    currentDate.getTime() - 3 * 24 * 60 * 60 * 1000,
  );

  return threeDaysAgo <= new Date(inventoryMigrationInitiationDate);
};

export const selectInventoryNameDateValidator = createSelector(
  selectAllInventories,
  selectInventoryValidators(),
  (inventories, validators) => {
    let nameAndDateExists = false;
    if (
      validators.inventoryDate === undefined ||
      validators.inventoryName === undefined ||
      validators.inventoryDate === '' ||
      validators.inventoryName === ''
    ) {
      return false;
    }

    inventories.forEach(inventory => {
      let nameCheck = false;
      let dateCheck = false;
      if (
        !nameAndDateExists &&
        inventory !== undefined &&
        inventory.inventoryName &&
        inventory.inventoryDate
      ) {
        nameCheck =
          (inventory.inventoryName as string) === validators.inventoryName;
        const dateOne: Date = grabDateFromStringDateType(
          inventory.inventoryDate,
        );
        const dateTwo: Date = grabDateFromStringDateType(
          validators.inventoryDate,
        );
        dateCheck = areDatesEqual(dateOne, dateTwo);

        if (nameCheck && dateCheck) {
          nameAndDateExists = true;
        }
      }
    });

    return nameAndDateExists;
  },
);
