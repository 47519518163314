<ion-header
  class="ion-no-border ion-header-modal-mobile"
  [class.view-selected-prods]="selectedAction === ListActions.viewSelected"
>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="selected-product-back-modal-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="selected-action-text">
      {{ title }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="copy-container">
    <ion-item class="message-container">
      {{ 'i18n.lists.copyProductsModal.copyProductsGroupTarget' | translate }}
    </ion-item>
    <div class="groups-list">
      <ng-container *ngFor="let group of selectedGroups">
        <ion-item
          *ngIf="showGroup(group)"
          (click)="onSelectGroup(group.groupState.groupName, group.isSelected)"
          data-cy="click-select-group-item"
        >
          <div class="label-container">
            <ion-label data-cy="selected-products-group-name-text">{{
              group.groupState.groupName
            }}</ion-label>
            <ion-icon
              *ngIf="group.isSelected"
              name="checkmark-circle"
            ></ion-icon>
            <ion-icon
              class="radio-button-off"
              *ngIf="!group.isSelected"
              name="radio-button-off"
            ></ion-icon>
          </div>
        </ion-item>
      </ng-container>
    </div>
  </div>
</ion-content>
<ion-footer
  *ngIf="selectedAction !== ListActions.viewSelected"
  class="ion-footer-modal"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        *ngIf="selectedAction === ListActions.copy && useNewCopyProduct"
        data-cy="click-cancel-selected-products"
        class="usf-outline-green-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-selected-products-groups-modal-button"
        [ngClass]="
          numGroupsSelected < 1
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="dispatchAction()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
