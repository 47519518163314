import { Component, ViewChild } from '@angular/core';
import { RightPaneComponent } from '../right-pane/right-pane.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  template: '',
})
export class SearchablePaneComponent extends RightPaneComponent {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  constructor() {
    super();
  }
  resetSearch = () => {
    this.right.searchPane.searchKey = '';
    this.right?.searchPane?.searchBehavior.next('');
    if (!!this.right?.searchPane?.hideSearch) {
      return;
    }
    if (!!this.virtualScroll) {
      this.scrollToTop();
    }
  };

  handleSearch = () => {
    this.right?.searchPane?.searchBehavior.next(
      this.right?.searchPane?.searchKey ?? '',
    );
    if (!!this.right?.searchPane?.hideSearch) {
      return;
    }
    if (!!this.virtualScroll) {
      this.scrollToTop();
    }
  };

  scrollToTop = () => {
    this.platform !== this.platformRef.mobile
      ? this.virtualScroll.scrollToIndex(0)
      : this.virtualScroll.scrollToIndex(0, 'smooth');
  };
}
