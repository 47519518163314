/* eslint-disable no-shadow */
export enum PageTitle {
  allInvoices = 'i18n.pageTitles.allInvoices',
  aog = 'i18n.pageTitles.aog',
  askAQuestionPage = 'i18n.pageTitles.askAQuestionPage',
  betterBuys = 'i18n.pageTitles.betterBuys',
  browseProducts = 'i18n.pageTitles.browseProducts',
  browseProductCategoriesPage = 'i18n.pageTitles.browseProductCategoriesPage',
  browseProductSubCategoriesPage = 'i18n.pageTitles.browseProductSubCategoriesPage',
  createInventory = 'i18n.pageTitles.createInventory',
  copyInventory = 'i18n.pageTitles.copyInventory',
  home = 'i18n.pageTitles.home',
  inventory = 'i18n.pageTitles.inventory',
  lists = 'i18n.pageTitles.lists',
  login = 'i18n.pageTitles.login',
  makePayment = 'i18n.pageTitles.makePayment',
  manageNonUsfProducts = 'i18n.pageTitles.manageNonUsfProducts',
  manageVendors = 'i18n.pageTitles.manageVendors',
  menu = 'i18n.pageTitles.menu',
  myOrders = 'i18n.pageTitles.myOrders',
  orderInsights = 'i18n.pageTitles.orderInsights',
  myProfile = 'i18n.pageTitles.myProfile',
  noteDetails = 'i18n.pageTitles.noteDetails',
  notifications = 'i18n.pageTitles.notifications',
  orderGuide = 'i18n.pageTitles.orderGuide',
  orderSubmitted = 'i18n.pageTitles.orderSubmitted',
  invoicesAndPayments = 'i18n.pageTitles.invoicesAndPayments',
  paymentActivity = 'i18n.pageTitles.paymentActivity',
  paymentDashboard = 'i18n.pageTitles.paymentDashboard',
  paymentPreferences = 'i18n.pageTitles.paymentPreferences',
  paymentConfirmation = 'i18n.pageTitles.paymentConfirmation',
  paymentsNoFlag = 'i18n.pageTitles.paymentsNoFlag',
  arStatements = 'i18n.pageTitles.statements',
  productDetail = 'i18n.pageTitles.productDetail',
  profile = 'i18n.pageTitles.profile',
  recentlyPurchased = 'i18n.pageTitles.recentlyPurchased',
  requestCredit = 'i18n.pageTitles.requestCredit',
  requestCreditConfirmation = 'i18n.pageTitles.requestCreditConfirmation',
  reviewAndSubmit = 'i18n.pageTitles.reviewAndSubmit',
  reviewOrder = 'i18n.pageTitles.reviewOrder',
  search = 'i18n.pageTitles.search',
  shopProducts = 'i18n.pageTitles.shopProducts',
  searchResults = 'i18n.pageTitles.searchResults',
  submittedOrder = 'i18n.pageTitles.submittedOrder',
  writeAProductReview = 'i18n.pageTitles.writeAProductReview',
  deals = 'i18n.pageTitles.deals',
  allDeals = 'i18n.pageTitles.allDeals',
  didYouForget = 'i18n.pageTitles.didYouForget',
  topSellers = 'i18n.pageTitles.topSellers',
  trendingNow = 'i18n.pageTitles.trendingNow',
  addToInventory = 'i18n.pageTitles.addToInventory',
  allInventories = 'i18n.pageTitles.allInventories',
  foodCostCalculator = 'i18n.pageTitles.foodCostCalculator',
  searchCompare = 'i18n.pageTitles.searchCompare',
  importedOrders = 'i18n.pageTitles.importedOrders',
  quickOrderEntry = 'i18n.pageTitles.quickOrderEntry',
  equipmentAndSupplies = 'i18n.pageTitles.equipmentAndSupplies',
  serveYou = 'i18n.pageTitles.serveYou',
  whileSuppliesLast = 'i18n.pageTitles.whileSuppliesLast',
  productsDeals = 'i18n.pageTitles.productsDeals',
  myBusiness = 'i18n.pageTitles.myBusiness',
  myOrdersMenu = 'i18n.pageTitles.myOrdersMenu',
  scoop = 'i18n.pageTitles.scoop',
  supportCenter = 'i18n.pageTitles.supportCenter',
  ebCop = 'i18n.pageTitles.ebCop',
  ebCore = 'i18n.pageTitles.ebCore',
  listUpdates = 'i18n.pageTitles.listUpdates',
  glenviewFarms = 'i18n.pageTitles.glenviewFarms',
  stockYards = 'i18n.pageTitles.stockYards',
  crossValleyFarms = 'i18n.pageTitles.crossValleyFarms',
  devonshire = 'i18n.pageTitles.devonshire',
  allscoop = 'i18n.pageTitles.allscoop',
  monarch = 'i18n.pageTitles.monarch',
  stockYardsAngusBeef = 'i18n.pageTitles.stockYardsAngusBeef',
  rykoffSexton = 'i18n.pageTitles.rykoffSexton',
  harborBanks = 'i18n.pageTitles.harborBanks',
  patuxentFarms = 'i18n.pageTitles.patuxentFarms',
  harvestValue = 'i18n.pageTitles.harvestValue',
}
