<ion-header
  class="ion-no-border"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-header-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="platform === platformRef.desktop"
        id="close-modal-btn"
        data-cy="close-copy-to-list-modal-icon"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label [attr.data-cy]="'copy-to-list-title'" class="title-bold">
      {{ this.title | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  scrollY="false"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-content-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
  "
  tabletTouchMove
>
  <div
    class="load-container"
    *ngIf="
      (listsLoaded$ | optionalAsync$: false | async) === false as dataReady
    "
  >
    <ion-searchbar
      [attr.data-cy]="'temp-searchbar'"
      class="search"
      mode="md"
      placeholder="{{ listSearch | translate }}"
    >
    </ion-searchbar>
    <div class="sorry-loading">
      <p>
        {{ 'i18n.lists.copyProductsModal.load' | translate
        }}<ion-spinner name="circles"></ion-spinner>
      </p>
    </div>
  </div>

  <div
    class="container"
    *ngIf="(listsLoaded$ | optionalAsync$: false | async) === true as dataReady"
  >
    <div class="sub-container">
      <app-multi-select-checkbox
        *ngIf="dataReady"
        [right]="rightPaneData"
        [left]="leftPaneData"
        [platform]="platform"
        [dataCyTag]="'copy-to-list'"
        (checkValidity)="checkValidity()"
      >
      </app-multi-select-checkbox>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        [attr.data-cy]="'copy-to-list-cancel-button'"
        [ngClass]="
          platform === platformRef.mobile
            ? 'usf-outline-green-mobile-modal-button'
            : 'usf-outline-green-dsktp-tblt-modal-button'
        "
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>

      <ion-button
        [attr.data-cy]="'copy-to-list-next-button'"
        [ngClass]="
          rightPaneData.disable
            ? platform === platformRef.mobile
              ? 'usf-fill-disabled-mobile-modal-button'
              : 'usf-fill-disabled-dsktp-tblt-modal-button'
            : platform === platformRef.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="next()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
