<!-- Add File -->
<input
  type="file"
  #fileInput
  data-cy="hidden-file-click"
  class="hidden"
  (click)="simulateDrop()"
  (change)="onChange($event)"
/>

<div
  data-cy="file-card"
  class="file-card"
  [class.error]="error | async"
  [class.drag]="showDrag"
  *ngIf="!file && !showSpinner"
  (click)="onAddFile()"
  (drop)="onDrop($event)"
  (dragover)="dragging($event)"
  (dragleave)="clearDrag($event)"
  (dragenter)="enterDrag($event)"
>
  <div data-cy="add-file-button" class="add-file">
    <ion-icon data-cy="add-file-icon" name="reader-outline"></ion-icon>
    <ion-label class="item-subtitle-regular go-green" data-cy="add-file-text">
      {{ 'i18n.requestCredit.uploadAFile' | translate }}
    </ion-label>
  </div>
</div>
<!-- View File -->
<div
  data-cy="file-card"
  class="file-card"
  [class.error]="error | async"
  *ngIf="!!file && !showSpinner"
>
  <div class="view-file">
    <div class="file-info">
      <ion-icon
        data-cy="view-file-icon"
        class="icon-file"
        name="reader"
      ></ion-icon>
      <ion-label data-cy="view-file-text">
        {{ file?.name | truncateWithEllipsis: 20 }}
      </ion-label>
    </div>
    <div class="delete-change-file">
      <a data-cy="delete-file-button" (click)="onDeleteFile()">
        {{ 'i18n.common.delete' | translate }}
      </a>
      <span *ngIf="showChangeButton">|</span>
      <a
        *ngIf="showChangeButton"
        data-cy="change-file-button"
        (click)="onAddFile()"
      >
        {{ 'i18n.common.change' | translate }}
      </a>
    </div>
  </div>
</div>
<div data-cy="file-spinner-card" class="file-card" *ngIf="showSpinner">
  <app-loading-spinner></app-loading-spinner>
</div>
<p class="bad-data" *ngIf="error | async">
  {{ 'i18n.lists.importListModal.fileError' | translate }}
</p>
