import { Component } from '@angular/core';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';

@Component({
  selector: 'app-single-select-product',
  templateUrl: './single-select-product.component.html',
  styleUrls: [
    './single-select-product.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class SingleSelectProductComponent extends SearchablePaneComponent {
  constructor() {
    super();
  }
  productSelected(productNumber: number) {
    this.right.selectedItem = productNumber;
    this.right?.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      this.right.selectedItem,
    );
    this.checkValidity.emit();
  }
}
