import { Component, Input } from '@angular/core';
import { RightPaneComponent } from '../right-pane/right-pane.component';
import { InputLabelItems } from '@shared/models/dual-pane-model';
import { MultiInputItems } from '@shared/constants/dual-pane.enum';

// Documentation: https://usfoods.atlassian.net/wiki/x/5IDSJg
@Component({
  selector: 'app-multi-input-and-label',
  templateUrl: './multi-input-and-label.component.html',
  styleUrls: [
    './multi-input-and-label.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class MultiInputAndLabelComponent extends RightPaneComponent {
  @Input() title: string;
  multiInputItemsRef = MultiInputItems;

  constructor() {
    super();
  }

  interactWithInput(value: any, item: InputLabelItems, index: number) {
    this.right?.inputsPane?.handleValueUpdate(
      this.left,
      this.right,
      item.onInput(item, value?.detail?.value),
      index,
    );
    this.checkValidity.emit();
  }

  interactWithRange(
    value: any,
    isRangeOne: boolean,
    item: InputLabelItems,
    index: number,
  ) {
    this.right?.inputsPane?.handleValueUpdate(
      this.left,
      this.right,
      item.onRange(item, value?.detail?.value, isRangeOne),
      index,
    );
    this.checkValidity.emit();
  }

  handleBlur(item: InputLabelItems) {
    item.isBeingInteracted = false;
  }

  handleFocus(item: InputLabelItems) {
    item.isBeingInteracted = true;
  }
}
