import { ProductPricing, SellerPriceDetails } from '@usf/price-types';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { Customer } from '@usf/customer-types';

export const calculateProductPrice = (
  productPricing: ProductPricing,
  productSummary: ProductSummary,
) => {
  if (productPricing && productSummary) {
    const { unitPrice } = productPricing;
    const { catchWeightFlag, netWeight } = productSummary;
    if (catchWeightFlag) {
      return unitPrice * (netWeight || 1);
    }
    return unitPrice;
  }
};

// price per unit
const ALLOWED_COMPARISON_UOM_SET = new Set([
  'lb',
  'oz',
  'piece',
  'bg',
  'bu',
  'cn',
  'gr',
  'kg',
  'ea',
  'dz',
  'ft',
  'ga',
  'kt',
  'lt',
  'ml',
  'pk',
  'pr',
  'pt',
  'qt',
  'rl',
  'st',
]);

const isNotCatchWeight = (summary: ProductSummary) =>
  summary?.catchWeightFlag !== true;

const customerCanSeePrice = (customer: Customer): boolean =>
  customer?.ogPrintPriceInd !== 'N';

const isPriceDefined = (pricing: ProductPricing): boolean =>
  pricing?.unitPrice > 0;

const isPriceDisplayable = (summary: ProductSummary): boolean =>
  !summary?.properties?.has(ProductPropertiesEnum.specialOrder);

const isAllowedUOM = (comparisonUOM: string): boolean => {
  const uom = comparisonUOM?.trim().toLowerCase() ?? '';
  return ALLOWED_COMPARISON_UOM_SET.has(uom);
};

const isValidComparisonValue = (comparisonValue: string): boolean => {
  return Number(comparisonValue) > 0;
};

const formatUOM = (comparisonUOM: string, summary: ProductSummary): string => {
  switch (comparisonUOM?.toLowerCase()) {
    case 'piece':
      return comparisonUOM.toLowerCase();
    case 'ea':
      if (summary?.breakable) {
        return 'PC';
      }
  }
  return comparisonUOM.toUpperCase();
};

const calculateCostBreakdown = (
  summary: ProductSummary,
  customer: Customer,
  pricing: ProductPricing,
  comparisonUOM: string,
  comparisonValue: string,
): string => {
  if (
    isNotCatchWeight(summary) &&
    customerCanSeePrice(customer) &&
    isPriceDefined(pricing) &&
    isPriceDisplayable(summary) &&
    isAllowedUOM(comparisonUOM) &&
    isValidComparisonValue(comparisonValue)
  ) {
    const calculatedPricePerUOM = pricing.unitPrice / Number(comparisonValue);
    const roundedPricePerUOM = calculatedPricePerUOM.toFixed(2);
    return `$${roundedPricePerUOM} / ${formatUOM(comparisonUOM, summary)}`;
  }

  return null;
};

const calculatePricePerPortion = (
  summary: ProductSummary,
  customer: Customer,
  pricing: ProductPricing,
  comparisonUOM: string,
  comparisonValue: string,
): number => {
  if (
    isNotCatchWeight(summary) &&
    customerCanSeePrice(customer) &&
    isPriceDefined(pricing) &&
    isPriceDisplayable(summary) &&
    isAllowedUOM(comparisonUOM) &&
    isValidComparisonValue(comparisonValue)
  ) {
    const calculatedPricePerUOM = pricing.unitPrice / Number(comparisonValue);
    return calculatedPricePerUOM;
  }

  return null;
};

const getCaseContents = (
  comparisonUOM: string,
  comparisonValue: string,
  summary: ProductSummary,
) => {
  if (isAllowedUOM(comparisonUOM) && isValidComparisonValue(comparisonValue)) {
    return `${Number(comparisonValue).toFixed(2)} ${formatUOM(
      comparisonUOM,
      summary,
    )}`;
  }
  return null;
};

const getMaxMinTargetPrices = (sellerPriceDetail: SellerPriceDetails) => {
  if (sellerPriceDetail) {
    const {
      currentPrice: { cookbookPrices, divisionCosts },
    } = sellerPriceDetail;

    const cookbookMaximumPrice = cookbookPrices?.cookbookMaximumPrice;
    const cookbookMinimumPrice = cookbookPrices?.cookbookMinimumPrice;
    const listAmount = divisionCosts?.listAmount;
    const targetPrice = cookbookPrices?.cookbookTargetPrice;
    const sellAmount = divisionCosts?.sellAmount;

    const maximum =
      cookbookMaximumPrice > 0 ? cookbookMaximumPrice : listAmount;
    const minimum =
      cookbookMinimumPrice > 0
        ? cookbookMinimumPrice
        : divisionCosts.floorAmount;
    const target = targetPrice > 0 ? targetPrice : sellAmount;
    return { maximum, minimum, target };
  }
  return null;
};

export const ProductPriceUtil = {
  calculateCostBreakdown,
  getCaseContents,
  getMaxMinTargetPrices,
  calculatePricePerPortion,
};
