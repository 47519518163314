<ng-container
  *ngIf="right?.searchPane?.searchableObservable$ | async as searchableItems"
>
  <ion-label
    [attr.data-cy]="dataCyTag + '-seaarch-title'"
    *ngIf="!!right?.searchPane?.searchTitle"
    class="subtitle search-title"
  >
    {{ right?.searchPane?.searchTitle | translate }}
  </ion-label>
  <ion-searchbar
    class="search"
    mode="md"
    [attr.data-cy]="dataCyTag + '-search-bar'"
    placeholder="{{ right?.searchPane?.searchPlaceholder | translate }}"
    [(ngModel)]="right.searchPane.searchKey"
    (ionClear)="resetSearch()"
    (ionInput)="handleSearch()"
  ></ion-searchbar>
  <div
    data-cy="no-results-found"
    class="no-results-found"
    *ngIf="searchableItems?.length === 0"
  >
    {{
      right?.searchPane?.noResultsOverride ??
        'i18n.lists.copyListModal.noResultsFound' | translate
    }}
  </div>
  <ion-list
    [ngClass]="
      platform === platformRef.desktop
        ? 'desktop'
        : platform === platformRef.tablet
          ? 'tablet'
          : 'mobile'
    "
  >
    <app-selectable-countable-item
      *ngFor="let item of searchableItems"
      [item]="item"
      [isSelected]="right?.selectedItem === item"
      (selectItemEmitter)="itemSelected($event)"
    >
    </app-selectable-countable-item>
  </ion-list>
</ng-container>
