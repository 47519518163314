<div
  *ngIf="pricingAvailable && (savings > 0 || annualSavings > 0)"
  class="savings-row"
  [class.row]="flexDirection === 'row'"
  [class.column]="flexDirection === 'column'"
>
  <ng-container
    *ngIf="
      flexDirection === 'row';
      then ListDetailCard;
      else BetterBuyStandAlone
    "
  >
  </ng-container>

  <ng-template #ListDetailCard>
    <div
      *ngIf="
        (savings > 0 || annualSavings > 0 || savingsPerCase > 0) &&
        platformType !== platformEnum.mobile
      "
      class="savings-box-list"
      data-cy="listdetails-better-buy-savings-section"
    >
      <div
        class="list-savings-section"
        data-cy="listdetails-annual-savings-section"
      >
        <span
          *ngIf="annualSavings > 0"
          data-cy="listdetails-annual-savings-text"
        >
          <span class="list-annual-swap">
            {{ 'i18n.betterBuys.annualSwapSavings' | translate }}
          </span>
          <span class="list-savings-text">{{ annualSavings | currency }}</span>
          <span class="mini-bar"> | </span>
        </span>
        <span
          *ngIf="savingsPerCase > 0"
          data-cy="listdetails-savings-per-case-section"
        >
          <span class="list-savings-text">{{ savingsPerCase | currency }}</span>

          <span class="mini-separater"> / </span>
          <span class="uom"> {{ 'i18n.betterBuys.case' | translate }} </span>
          <span class="mini-bar"> | </span>
        </span>
        <span *ngIf="savings > 0" data-cy="listdetails-savings-section">
          <span class="list-savings-text">{{ savings | currency }}</span>
          <span class="mini-separater"> / </span>
          <span class="uom">
            {{
              (savingsUom === 'SV' ? 'i18n.betterBuys.serving' : savingsUom)
                | translate
            }}</span
          >
        </span>
      </div>
    </div>

    <div
      *ngIf="
        (savings > 0 || annualSavings > 0 || savingsPerCase > 0) &&
        platformType === platformEnum.mobile
      "
      class="savings-box-list-mobile"
      data-cy="listdetails-better-buy-savings-section-mobile"
    >
      <div data-cy="listdetails-annual-savings-section-mobile">
        <div
          *ngIf="annualSavings > 0"
          data-cy="listdetails-annual-savings-text-mobile"
        >
          <span class="list-annual-swap-mob"
            >{{ 'i18n.betterBuys.annualSwapSavings' | translate }}
          </span>
          <span class="list-annual-savings-text-mob">{{
            annualSavings | currency
          }}</span>
        </div>
        <div class="mobile-two-lines">
          <div
            *ngIf="savingsPerCase > 0"
            data-cy="listdetails-savings-per-case-section-mobile"
          >
            <span class="list-annual-savings-text-mob">{{
              savingsPerCase | currency
            }}</span>

            <span class="mini-separater-mob"> / </span>
            <span class="list-annual-swap-mob"
              >{{ 'i18n.betterBuys.case' | translate }}
            </span>
            <span class="mini-bar-mob"> | </span>
          </div>
          <div *ngIf="savings > 0" data-cy="listdetails-savings-section-mobile">
            <span class="list-annual-savings-text-mob">{{
              savings | currency
            }}</span>
            <span class="mini-separater-mob"> / </span>
            <span class="list-annual-swap-mob">
              {{
                (savingsUom === 'SV' ? 'i18n.betterBuys.serving' : savingsUom)
                  | translate
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #BetterBuyStandAlone>
    <div
      *ngIf="savings > 0"
      class="savings-box"
      [ngClass]="annualSavings > 0 ? 'heightWithThree' : 'heightWithTwo'"
      data-cy="better-buysavings-section"
    >
      <div
        *ngIf="annualSavings > 0"
        class="annual-savings-section"
        data-cy="annual-savings-section"
      >
        <span class="annual-sawp"
          >{{ 'i18n.betterBuys.annualSwapSavings' | translate }}
        </span>
        <span class="annual-savings-text">{{ annualSavings | currency }}</span>
      </div>
      <div *ngIf="annualSavings > 0" data-cy="annual-savings-separator">
        <hr class="separator" />
      </div>
      <div class="second-line" data-cy="case-savings-section">
        <div *ngIf="savingsPerCase > 0" class="savings-section-case">
          <span class="savings-amount">{{ savingsPerCase | currency }}</span>

          <span class="mini-border"> / </span>
          <span class="uom"> {{ 'i18n.betterBuys.case' | translate }} </span>
        </div>
        <div
          class="savings-section-case"
          *ngIf="savings > 0"
          data-cy="savings-section"
        >
          <span class="savings-amount">{{ savings | currency }}</span>
          <span class="mini-border"> / </span>
          <span class="uom">
            {{
              (savingsUom === 'SV' ? 'i18n.betterBuys.serving' : savingsUom)
                | translate
            }}</span
          >
        </div>
      </div>
    </div>
  </ng-template>
</div>
