import { RecentPurchase } from '@usf/list-types';
import * as dayjs from 'dayjs';
import {
  dateIsLessThan,
  formatDate,
} from '../../product-detail/utils/date.util';
import { RecentPurchaseRecord } from '../models/recent-purchase.record';

export class RecentPurchaseHelper {
  static formatRecentPurchase(
    recentPurchase: RecentPurchase,
    dayjsFormat: string = 'MM/DD/YY',
  ): RecentPurchaseRecord[] {
    if (!recentPurchase) return [];
    return Object.keys(recentPurchase)
      .filter(b => b.search('purchasePrice') === 0)
      .map((_, index) => {
        if (!!recentPurchase[`purchaseDtm${index + 1}`]) {
          const record = {
            casesOrdered: recentPurchase[`unitsOrdered${index + 1}`],
            eachesOrdered: recentPurchase[`eachesOrdered${index + 1}`],
            dateOrderedRaw: dayjs(recentPurchase[`purchaseDtm${index + 1}`]),
            purchasePrice: recentPurchase[`purchasePrice${index + 1}`],
            invoiceNumber: recentPurchase[`invoiceNumber${index + 1}`],
          };
          return record;
        }
        return undefined;
      })
      .filter(
        record =>
          !!record &&
          dateIsLessThan(180, 'days', record.dateOrderedRaw) &&
          (record.casesOrdered > 0 || record.eachesOrdered > 0),
      )
      .map(record => {
        return {
          ...record,
          dateOrdered: formatDate(dayjsFormat, record.dateOrderedRaw),
        };
      })
      .sort(({ dateOrderedRaw: a }, { dateOrderedRaw: b }) =>
        a.isBefore(b) ? 1 : -1,
      );
  }
}
