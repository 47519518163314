import { Component, EventEmitter, Output } from '@angular/core';
import { of } from 'rxjs';
import { RightPaneComponent } from '../right-pane/right-pane.component';

@Component({
  selector: 'app-pick-a-file',
  templateUrl: './pick-a-file.component.html',
  styleUrls: ['./pick-a-file.component.scss', '../../dual-pane-shared.scss'],
})
export class PickAFileComponent extends RightPaneComponent {
  processingData: boolean;
  badData: boolean;
  @Output() fileAddEvent = new EventEmitter<void>();
  @Output() fileDeleteEvent = new EventEmitter<void>();

  showDrag = false;
  async onDeleteFile() {
    this.fileDeleteEvent.emit();
  }

  onAddFile = async () => {
    this.fileAddEvent.emit();
  };

  processFile = async (file: File) => {
    const fileIsValid = await this.right?.filePane?.verifyContent(
      file,
      this.left,
    );
    if (fileIsValid) {
      this.right.value = file;
      this.right.filePane?.fileSelected(
        this.left,
        this.platform,
        this.right,
        file,
      );
    } else {
      this.right.value = undefined;
      this.right.error = of(true);
    }
  };

  updateFile = (file: File) => {
    this.right.value = file;
  };

  handleFiles = async (files: FileList) => {
    const showMultipleFileError = files.length > 1;
    if (showMultipleFileError) {
      this.updateFile(undefined);
      return;
    }
    const file = files[0];
    await this.processFile(file);
  };

  async requestInfo() {
    this.right?.filePane?.requestInfo();
  }
}
