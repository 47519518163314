<ion-grid
  class="recent-purchase-grid"
  [ngClass]="
    (userKind$ | async) === userKinds.Internal ? 'internal' : 'external'
  "
  *ngIf="userKind$ | async as userKind"
>
  <ng-container
    *ngIf="userKind === userKinds.Internal; else externalUserContent"
  >
    <ion-row
      *ngFor="let purchaseRecord of recentPurchaseRecords"
      class="recent-purchase-record"
      data-cy="recent-purchase-record-section"
    >
      <ion-col size="2" data-cy="recent-purchase-record-cases-text"
        >{{ purchaseRecord.casesOrdered }}&nbsp;CS</ion-col
      >
      <ion-col size="2" data-cy="recent-purchase-record-eaches-text"
        >{{ purchaseRecord.eachesOrdered }}&nbsp;EA</ion-col
      >
      <ion-col size="2" data-cy="recent-purchase-record-purchase-price-text"
        >$&nbsp;{{ purchaseRecord.purchasePrice }}</ion-col
      >
      <ion-col size="4" data-cy="recent-purchase-record-invoice-number-text">
        {{
          !!purchaseRecord.invoiceNumber
            ? (platformService.platformType !==
              platformService.platformEnum.mobile
                ? 'Inv # '
                : '# ') + purchaseRecord.invoiceNumber
            : '-'
        }}&nbsp;
      </ion-col>
      <ion-col size="2" data-cy="recent-purchase-record-date-text">{{
        purchaseRecord.dateOrdered
      }}</ion-col>
    </ion-row>
  </ng-container>
  <ng-template #externalUserContent>
    <ion-row
      *ngFor="let purchaseRecord of recentPurchaseRecords"
      class="recent-purchase-record"
      data-cy="recent-purchase-record-section"
    >
      <ion-col size="4" data-cy="recent-purchase-record-cases-text"
        >{{ purchaseRecord.casesOrdered }}&nbsp;CS</ion-col
      >
      <ion-col size="4" data-cy="recent-purchase-record-eaches-text"
        >{{ purchaseRecord.eachesOrdered }}&nbsp;EA</ion-col
      >
      <ion-col size="4" data-cy="recent-purchase-record-date-text">{{
        purchaseRecord.dateOrdered
      }}</ion-col>
    </ion-row>
  </ng-template>
</ion-grid>
