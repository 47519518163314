import { Component, Input } from '@angular/core';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { IonNav, ViewWillEnter } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import {
  getSearchableObservable$,
  searchByGroup,
  updateSelectAllByGroupPostSelection,
} from '@shared/components/modal-with-nav-inline/dual-pane-container/Helpers/dual-pane-helper-functions';
import {
  DualPaneView,
  MultiSelectItems,
} from '@shared/constants/dual-pane.enum';
import {
  DualPaneViewModel,
  LeftPaneInfo,
  RightPaneInfo,
} from '@shared/models/dual-pane-model';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { VerifyCopiedProductsModalComponent } from '../verify-copied-products-modal/verify-copied-products-modal.component';

@Component({
  selector: 'app-copy-to-lists-modal',
  templateUrl: './copy-to-lists-modal.component.html',
  styleUrls: ['./copy-to-lists-modal.component.scss'],
})
export class CopyToListsModalComponent implements ViewWillEnter {
  @Input() platform: PlatformEnum;
  @Input() dismissFunc: Function;
  @Input() getListData: Function;
  @Input() finalSubmission: Function;
  @Input() getListsLoaded: Function;
  @Input() title: string;
  @Input() selectedProducts: ProductRow[];

  listSearch = 'i18n.lists.copyProductsModal.search';

  listsData$ = of([]);
  listsLoaded$ = of(false);
  searchBehavior = new BehaviorSubject<string>('');

  rightPaneData: RightPaneInfo = {
    view: DualPaneView.multiSelectCheckbox,
    searchPane: {
      searchPlaceholder: this.listSearch,
      onSearchFunction: searchByGroup,
      searchBehavior: this.searchBehavior,
      searchKey: '',
    },
    countPane: {
      primaryCount: 0,
      hideSelectAll: true,
      selectableText: 'listId',
      alternateBolding: true,
      selectAllRefreshFunc: updateSelectAllByGroupPostSelection,
    },
    itemsObservable$: of([]),
    verifySaveButton: (vm: DualPaneViewModel) => {
      const selections = vm.rightView.value.filter(item => !!item.selected);
      return selections.length === 0;
    },
    disable: true,
  };
  leftPaneData: LeftPaneInfo = {
    view: DualPaneView.multiSelectCheckbox,
  };
  platformRef = PlatformEnum;
  constructor(private ionNav: IonNav) {}

  async ionViewWillEnter() {
    await this.setupData();
  }

  setupData = async () => {
    if (!!this.rightPaneData.value && this.rightPaneData.value.length > 0) {
      return;
    }
    this.listsData$ = await this.getListData();
    this.listsLoaded$ = await this.getListsLoaded();
    this.rightPaneData.itemsObservable$ = this.listsData$;
    this.rightPaneData.searchPane.searchableObservable$ =
      getSearchableObservable$(this.rightPaneData);
    this.rightPaneData.handleItemSelection = this.handleItemSelection;
  };

  dismissModal() {
    this.dismissFunc();
  }

  getFiltredDownValue = fullListData => {
    const sortByName = (nameA: string, nameB: string) => {
      if (nameA.toLowerCase() > nameB.toLowerCase()) {
        return 1;
      } else if (nameA.toLowerCase() < nameB.toLowerCase()) {
        return -1;
      }
      return 0;
    };
    const headers = fullListData.filter(
      item => item.type === MultiSelectItems.selectableHeader,
    );
    let lists = [];

    const sortAndIndex = (items: any[]) => {
      let index = 1;
      const itemsToAppend = items
        .sort((a, b) => sortByName(a.label, b.label))
        .map(item => {
          item.subLabel = index.toString();
          index++;
          return item;
        });

      lists.push(...itemsToAppend);
    };

    let lastBatch = [];
    let lastGrouping;

    this.rightPaneData.value
      .filter(
        item =>
          !!item.selected && item.type !== MultiSelectItems.selectableHeader,
      )
      .forEach(element => {
        if (element.group !== lastGrouping) {
          sortAndIndex(lastBatch);
          lastBatch = [];
          lastGrouping = element.group;
          lists.push(headers.find(item => item.group === lastGrouping));
        }
        lastBatch.push(element);
      });

    sortAndIndex(lastBatch);
    return lists;
  };
  next = async () => {
    const fullListData = await firstValueFrom(
      this.rightPaneData.itemsObservable$,
    );
    let needToReMap = false;
    let productsToSend = [];
    this.selectedProducts.forEach(item => {
      if (!!(item as any)?.product && !!(item as any)?.item) {
        needToReMap = true;
      }
    });
    if (needToReMap) {
      productsToSend = this.selectedProducts.map(item => (item as any).product);
    } else {
      productsToSend = [...this.selectedProducts];
    }
    this.ionNav.push(VerifyCopiedProductsModalComponent, {
      platform: this.platform,
      dismissFunc: this.dismissFunc,
      selectedLists: this.getFiltredDownValue(fullListData),
      selectedProducts: productsToSend,
      finalSubmission: this.finalSubmission,
    });
  };

  checkValidity() {}

  handleItemSelection = (
    left: LeftPaneInfo,
    platform: PlatformEnum,
    right: RightPaneInfo,
    value: any,
  ) => {
    right.value = value;
  };
}
