import { Component } from '@angular/core';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';

@Component({
  selector: 'app-single-select-radio',
  templateUrl: './single-select-radio.component.html',
  styleUrls: [
    './single-select-radio.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class SingleSelectRadioComponent extends SearchablePaneComponent {
  constructor() {
    super();
  }

  handleRadioSelection = $event => {
    this.right.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      $event?.detail?.value,
    );
    this.checkValidity.emit();
  };
}
