import { PlatformEnum } from '@panamax/app-state';
import { dualPaneSelectInventoryCheck } from '@shared/constants/dual-pane-constants';
import {
  DualPaneView,
  MultiSelectItems,
} from '@shared/constants/dual-pane.enum';
import {
  BasicListItem,
  DualPaneViewModel,
  InputLabelItems,
  LeftPaneInfo,
  RightPaneInfo,
  SelectableItem,
} from '@shared/models/dual-pane-model';
import { combineLatest, map, Observable } from 'rxjs';
import { PickedFile } from '@capawesome/capacitor-file-picker';
import { RadioBtnInventory } from '../../../../../inventory/models/radio-btn-inventory.model';
import { areDatesEqual } from '@shared/helpers/calendar.helpers';
import {
  currencyCleanup,
  trimSpaceForInput,
} from '@shared/helpers/input-cleanup.helpers';

// Left pane selection functions
export const dualPaneDefaultTouchSelection = (
  viewModel: DualPaneViewModel,
  item: BasicListItem,
) => {
  return {
    ...viewModel,
    navigationPageCount: viewModel.navigationPageCount + 1,
    rightView: !item.onSelectfunction
      ? {
          ...viewModel.rightView,
          view: item.view,
        }
      : item.onSelectfunction(viewModel, item.label),
    leftView: {
      ...viewModel.leftView,
      view: DualPaneView.noView,
    },
    submitIsBackButton: true,
  } as DualPaneViewModel;
};

export const multiInventorySelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  value: any,
) => {
  let tempArray = [...(right.value ?? [])] as any[];
  if (value?.selected) {
    tempArray.push(value);
  } else {
    const index = tempArray.findIndex(invCheck => {
      return inventoryRadioMatch(invCheck, value);
    });
    tempArray = tempArray.filter((val, i) => {
      return i !== index;
    });
  }
  right.value = tempArray;

  let subLabel = undefined;
  if (tempArray.length === 1) {
    subLabel = tempArray[0].inventoryName;
  } else if (tempArray.length > 1) {
    subLabel = 'Selected: ' + tempArray.length;
  }

  left.content = left.content.map(item => {
    if (item.selected) {
      return {
        ...item,
        subLabel,
        value: tempArray,
      };
    } else {
      return item;
    }
  });
};

export const dualPaneDefaultDesktopTabletSelection = (
  viewModel: DualPaneViewModel,
  item: BasicListItem,
) => {
  return {
    ...viewModel,
    rightView: !item.onSelectfunction
      ? {
          ...viewModel.rightView,
          view: item.view,
        }
      : item.onSelectfunction(viewModel, item.label),
  } as DualPaneViewModel;
};

// Right pane selection functions
export const dualPaneBasicSelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  value: any,
) => {
  left.content = left.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: value, value: value };
    } else {
      return item;
    }
  });
  if (right.view !== DualPaneView.noView) {
    right.value = value;
  }
};

export const radioBtnInventorySelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  value: any,
) => {
  left.content = left.content.map(item => {
    if (item.selected) {
      return {
        ...item,
        subLabel: value?.inventoryName,
        value,
      };
    } else {
      return item;
    }
  });
  if (right.view !== DualPaneView.noView) {
    right.value = value;
  }
};

export const dualPaneMultiSelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  value: any,
  selectedCount: string,
) => {
  left.content = left.content.map(listItem => {
    if (listItem.selected) {
      return { ...listItem, subLabel: selectedCount, value: value };
    } else {
      return listItem;
    }
  });
  if (right.view !== DualPaneView.noView) {
    right.value = value;
  }
};

export const dualPaneBasicFileSelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  file: PickedFile,
) => {
  left.content = left.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: file.name, value: file };
    } else {
      return item;
    }
  });
};

// Submission handlers
export const dualPaneDefaultHandleSubmit = (
  vm: DualPaneViewModel,
): Map<string, any | any[]> => {
  const mapToReturn = new Map<string, any | any[]>();
  vm.leftView.content.forEach(item => {
    mapToReturn.set(item.label, item.value);
  });
  return mapToReturn;
};

export const dualPaneDefaultVerifySubmit = (vm: DualPaneViewModel) => {
  let valid = true;
  vm.leftView.content.forEach(item => {
    if (!item.value) {
      valid = false;
    }
  });
  return valid;
};

// Save verification functions
export const dualPaneDefaultVerifySave = (vm: DualPaneViewModel) => {
  return !!vm.rightView.value;
};

export const singleSelectInventoryVerifySave = (vm: DualPaneViewModel) => {
  return (
    !!vm.rightView.value && vm.rightView.value !== dualPaneSelectInventoryCheck
  );
};

export const multiSelectInventoryVerifySave = (vm: DualPaneViewModel) => {
  return (vm.rightView.value ?? []).length > 0;
};

// Navigation functions
export const inventoryMultiSaveOnNavigation = (
  vm: DualPaneViewModel,
  item: BasicListItem,
  value: any,
) => {
  let subLabel = undefined;
  if (value.length === 1) {
    subLabel = value[0].inventoryName;
  } else if (value.length > 1) {
    subLabel = 'Selected: ' + value.length;
  }
  item.value = value;
  item.subLabel = subLabel;
  return item;
};

export const fileSaveOnNavigation = (
  vm: DualPaneViewModel,
  item: BasicListItem,
  value: PickedFile,
) => {
  item.value = value;
  item.subLabel = value.name;
  return item;
};

// Pane building helpers
export const updateRightPaneWithValue = (
  viewModel: DualPaneViewModel,
  paneSelected: string,
  rightPane: RightPaneInfo,
) => {
  if (!!paneSelected) {
    viewModel.leftView.content.forEach(element => {
      if (element.label === paneSelected) rightPane.value = element.value;
    });
  }
};

export const updatePaneDirectlyWithValue = (
  value: any,
  rightPane: RightPaneInfo,
) => {
  rightPane.value = value;
};

export const updateRightPaneWithValueForMaps = (
  viewModel: DualPaneViewModel,
  paneSelected: string,
  rightPane: RightPaneInfo,
) => {
  if (!!paneSelected) {
    viewModel.leftView.content.forEach(element => {
      if (element.label === paneSelected) {
        rightPane.value = element.value;
        if (!!rightPane.value) {
          rightPane.value = new Map(rightPane.value);
        }
      }
    });
  }
};

export const getSearchableObservable$ = (rightPane: RightPaneInfo) => {
  return combineLatest([
    rightPane?.searchPane.searchBehavior,
    rightPane.itemsObservable$,
  ]).pipe(
    map(([search, items]) => {
      if (!!rightPane.countPane) {
        rightPane.countPane.secondaryCount = items.filter(
          item => item.type !== MultiSelectItems.iconHeader,
        ).length;
      }
      const searchReult = rightPane.searchPane.onSearchFunction(
        rightPane,
        search,
        items,
      );
      const itemHeights = [];
      searchReult.forEach(element => {
        // setup item heights logic
        itemHeights.push(60);
      });
      rightPane.searchPane.itemHeights = itemHeights;
      return searchReult;
    }),
  );
};

// Search functions
export const multiSelectSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    return (
      item?.label?.toLowerCase()?.includes((searchKey || '').toLowerCase()) ||
      item?.subLabel?.toLowerCase()?.includes((searchKey || '').toLowerCase())
    );
  });
};

export const singleSelectRadioSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    return (
      item?.label?.toLowerCase()?.includes((searchKey || '').toLowerCase()) ||
      item?.subLabel?.toLowerCase()?.includes((searchKey || '').toLowerCase())
    );
  });
};

export const countableItemSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    if (item?.title === rightPane?.value) {
      rightPane.selectedItem = item;
    }
    return (
      item?.title?.toUpperCase().includes((searchKey || '').toUpperCase()) ||
      item?.subtitle?.includes(searchKey || '')
    );
  });
};

export const radioBtnInventorySearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  const keyWords = [];

  (searchKey || '')
    ?.toUpperCase()
    .split('-')
    .forEach(nameSegment =>
      keyWords.push(...nameSegment.toLowerCase().split(' ')),
    );
  return items?.filter((item: RadioBtnInventory) => {
    if (!searchKey) {
      return true;
    }
    return keyWords.every(valueCheck => {
      return !!item.searchArray.find(key => {
        return !!key.toLowerCase().includes(valueCheck.toLowerCase());
      });
    });
  });
};

export const updateSelectAllByGroupPostSelection = (
  left: LeftPaneInfo,
  platform: PlatformEnum,
  right: RightPaneInfo,
  marketItemsArray: SelectableItem[],
  selectableItem?: SelectableItem,
) => {
  const groupKey = selectableItem?.group;
  if (!groupKey) {
    return;
  }
  const everythingAndTheKitchenSink = marketItemsArray.filter(
    item => item.group === selectableItem.group,
  );
  let everythingSelected = true;

  everythingAndTheKitchenSink.forEach(item => {
    if (!item.selected && item.type !== MultiSelectItems.selectableHeader) {
      everythingSelected = false;
    }
  });

  const header = everythingAndTheKitchenSink.find(
    item => item.type === MultiSelectItems.selectableHeader,
  );
  if (!!header) {
    header.selected = everythingSelected;
  }
  right.disable = right.verifySaveButton({
    leftView: left,
    rightView: right,
  } as unknown as DualPaneViewModel);
};

export const searchByGroup = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  const grouping = new Map<string, SelectableItem[]>();
  const keys = [];
  if (!searchKey) {
    items.forEach((item: SelectableItem) => {
      if (grouping.has(item.group)) {
        const items = grouping.get(item.group);
        items.push(item);
        grouping.set(item.group, [...items]);
      } else {
        keys.push(item.group);
        grouping.set(item.group, [item]);
      }
    });
  } else {
    const keyWords = [];
    searchKey
      .toLowerCase()
      .split('-')
      .forEach(searchSegment => keyWords.push(...searchSegment.split(' ')));

    items.forEach((item: SelectableItem) => {
      if (
        keyWords.every(keyWord => {
          return !!item.searchArray.find(searchSegment => {
            return !!searchSegment.includes(keyWord);
          });
        })
      ) {
        if (grouping.has(item.group)) {
          const items = grouping.get(item.group);
          items.push(item);
          grouping.set(item.group, [...items]);
        } else {
          keys.push(item.group);
          grouping.set(item.group, [item]);
        }
      }
    });
  }

  const finalalizedItems = [];
  keys.forEach(key => {
    let currentHeader: SelectableItem;
    let allSelected = true;
    let foundAtLeastOne = false;
    grouping.get(key).forEach(item => {
      if (item.type !== MultiSelectItems.selectableHeader) {
        foundAtLeastOne = true;
        if (!item.selected) allSelected = false;
      } else {
        currentHeader = item;
      }
    });

    if (!foundAtLeastOne) {
      grouping.delete(key);
    } else {
      currentHeader.selected = allSelected;
      finalalizedItems.push(...grouping.get(key));
    }
  });
  return finalalizedItems;
};

export const productSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  const productsToReturn = items.filter(product => {
    if (product?.productNumber === rightPane?.value) {
      rightPane.selectedItem = product?.productNumber;
    }
    return (
      product?.productDescription
        ?.toUpperCase()
        ?.includes((searchKey || '').toUpperCase()) ||
      product?.productNumber
        ?.toString()
        ?.toUpperCase()
        ?.includes((searchKey || '').toUpperCase())
    );
  });
  return productsToReturn;
};

// Pane helper functions
export const inventoryRadioMatch = (inv1, inv2) => {
  return (
    inv1?.inventoryName === inv2?.inventoryName &&
    inv1?.departmentName === inv2?.departmentName &&
    areDatesEqual(inv1?.inventoryDate, inv2?.inventoryDate)
  );
};

// On input interaction functions
export const onMultiInputDefault = (item: InputLabelItems, value: any) => {
  item.inputValue = !!item.inputcleanup ? item.inputcleanup(value) : value;
  return item.inputValue;
};

export const onMultiRangeDefault = (
  item: InputLabelItems,
  value: any,
  isRangeOne: boolean,
) => {
  if (isRangeOne) {
    item.rangeValueOne = !!item.inputcleanup ? item.inputcleanup(value) : value;
  } else {
    item.rangeValueTwo = !!item.inputcleanup ? item.inputcleanup(value) : value;
  }
  return { one: item.rangeValueOne, two: item.rangeValueTwo };
};

export const handleInputOrRangeValueSave = (
  left: LeftPaneInfo,
  pane: RightPaneInfo,
  value: any,
  index: number,
) => {
  if (!pane.value) {
    pane.value = new Map<number, any>();
  }
  pane.value.set(index, value);
  left.content = left.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: value, value: pane.value };
    } else {
      return item;
    }
  });
};

// Input cleanup
export const trimSpaceTo30 = (value: any) => {
  return trimSpaceForInput(value.toString().substring(0, 30));
};

export const rangeCleanupZeroToHundred = (value: any) => {
  if (!value && value !== 0) {
    return value;
  }

  const scrubAlphaRegex = new RegExp('[^\\d.-]', 'g');
  let regexValue = Math.round(
    Number(value.replace(scrubAlphaRegex, '')),
  ).valueOf();
  if (regexValue > 100) {
    return 100;
  }
  if (regexValue < 1) {
    return 1;
  }
  return regexValue;
};

export const onSmallChangeCurrencyCleanp = (value: any) => {
  return currencyCleanup(value, 7);
};

export const onLargeChangeCurrencyCleanp = (value: any) => {
  return currencyCleanup(value, 10);
};
