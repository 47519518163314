import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SHARE_REVIEW_ORDER_EMAIL_ACTIONS } from '../actions/share-review-order-via-email.actions';
import { TRACE_CONTEXT } from '../constants/tracing';
import { ShareReviewOrderViaEmailService } from '../services/share-review-order-via-email.service';

@Injectable({
  providedIn: 'root',
})
export class ShareReviewOrderViaEmailEffects {
  shareReviewOrderViaEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SHARE_REVIEW_ORDER_EMAIL_ACTIONS.shareReviewOrderViaEmail),
      switchMap(params =>
        this.shareReviewOrderViaEmailService
          .shareReviewOrderViaEmail(params.data)
          .pipe(
            switchMap(response => [
              SHARE_REVIEW_ORDER_EMAIL_ACTIONS.shareReviewOrderViaEmailSuccess({
                response,
                tracking: {
                  tracing: {
                    data: {
                      traceContext: TRACE_CONTEXT.shareReviewOrderViaEmail,
                      isStartOfTrace: false,
                      isEndOfTrace: true,
                    },
                  },
                },
              }),
            ]),
            catchError(err => {
              const error = { status: err.status, message: err.message };
              return of(
                SHARE_REVIEW_ORDER_EMAIL_ACTIONS.shareReviewOrderViaEmailFail({
                  error,
                  tracking: {
                    tracing: {
                      data: {
                        traceContext: TRACE_CONTEXT.shareReviewOrderViaEmail,
                        isStartOfTrace: false,
                        isEndOfTrace: true,
                      },
                    },
                  },
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private shareReviewOrderViaEmailService: ShareReviewOrderViaEmailService,
  ) {}
}
