import { Injectable } from '@angular/core';
import { ServiceHandlerService } from '@shared/services/service-handler.service';
import { ShareReviewOrderEmailInformation } from '@usf/file-share-types';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShareReviewOrderViaEmailService {
  constructor(private serviceHandler: ServiceHandlerService) {}

  shareReviewOrderViaEmail(
    data: ShareReviewOrderEmailInformation,
  ): Observable<any> {
    return this.serviceHandler
      .post<any>(environment.fileShareApiUrl + '/shareReviewOrder', data)
      ?.pipe(
        filter(resp => !!resp),
        catchError(error => throwError(error)),
      );
  }
}
