import { Component } from '@angular/core';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';
import { RadioBtnInventory } from '../../../../../inventory/models/radio-btn-inventory.model';

@Component({
  selector: 'app-multi-select-inventory',
  templateUrl: './multi-select-inventory.component.html',
  styleUrls: [
    './multi-select-inventory.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class MultiSelectInventoryComponent extends SearchablePaneComponent {
  constructor() {
    super();
  }

  inventorySelected(inventory: RadioBtnInventory) {
    inventory.selected = !inventory.selected;
    this.right?.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      inventory,
    );
    this.checkValidity.emit();
  }
}
