import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { ShareReviewOrderEmailInformation } from '@usf/file-share-types';

const shareReviewOrderViaEmail = createAction(
  '[NGRXTrace - File Share] Share Review Order Via Email',
  props<{ data: ShareReviewOrderEmailInformation; tracking?: Tracking }>(),
);

const shareReviewOrderViaEmailSuccess = createAction(
  '[NGRXTrace - File Share] Share Review Order Via Email Success',
  props<{ response: any; tracking?: Tracking }>(),
);

const shareReviewOrderViaEmailFail = createAction(
  '[NGRXTrace - File Share] Share Review Order Via Email Fail',
  props<{ error: any; tracking?: Tracking }>(),
);

export const SHARE_REVIEW_ORDER_EMAIL_ACTIONS = {
  shareReviewOrderViaEmail,
  shareReviewOrderViaEmailSuccess,
  shareReviewOrderViaEmailFail,
};
