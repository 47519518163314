import { Component } from '@angular/core';
import { MultiSelectItems } from '@shared/constants/dual-pane.enum';
import {
  DualPaneViewModel,
  SelectableItem,
} from '@shared/models/dual-pane-model';
import { Observable, firstValueFrom, map } from 'rxjs';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';
import { dualPaneMultiSelectPrefix } from '@shared/constants/dual-pane-constants';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: [
    './multi-select-checkbox.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class MultiSelectCheckboxComponent extends SearchablePaneComponent {
  MultiSelectItemsRef = MultiSelectItems;
  readonly PlatformTypeRef = PlatformEnum;

  constructor() {
    super();
  }

  trackItem(index: any, item: any) {
    return item ? index : undefined;
  }

  virtualscrolled(event: any) {
    event.stopPropagation();
  }

  handleCheckboxSelection = (
    $event,
    item: SelectableItem,
    fullList: SelectableItem[],
  ) => {
    const currentSelections = this.right.value ?? [];
    const selectedItems = new Map<string, SelectableItem>();
    currentSelections.forEach(selectedItem => {
      selectedItems.set(
        [
          selectedItem.label,
          !!this.right.countPane.selectableText
            ? selectedItem.value[this.right.countPane.selectableText]
            : selectedItem.value,
        ].join(),
        selectedItem,
      );
    });
    item.selected = $event?.detail?.checked;
    const keyArray = [
      item.label,
      !!this.right.countPane.selectableText
        ? item.value[this.right.countPane.selectableText]
        : item.value,
    ];
    const itemKey = keyArray.join();
    if ($event?.detail?.checked) {
      selectedItems.set(itemKey, item);
    } else {
      selectedItems.delete(itemKey);
    }
    this.right.countPane.primaryCount += $event?.detail?.checked ? 1 : -1;
    this.right.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      Array.from(selectedItems.values()),
      dualPaneMultiSelectPrefix + selectedItems.size,
      item,
    );
    if (!!this.right?.countPane?.selectAllRefreshFunc) {
      this.right?.countPane?.selectAllRefreshFunc(
        this.left,
        this.platform,
        this.right,
        fullList,
        item,
      );
    }
    this.checkValidity.emit();
  };

  handleSelectGroup = (
    $event,
    item: SelectableItem,
    searchedItems: SelectableItem[],
  ) => {
    const currentSelections = this.right.value ?? [];
    const selectedItems = new Map<string, SelectableItem>();
    currentSelections.forEach(selectedItem => {
      selectedItems.set(
        [
          selectedItem.label,
          !!this.right.countPane.selectableText
            ? selectedItem.value[this.right.countPane.selectableText]
            : selectedItem.value,
        ].join(),
        selectedItem,
      );
    });
    item.selected = $event?.detail?.checked;
    const groupedItems = searchedItems.filter(
      check => check.group === item.group,
    );
    groupedItems.forEach(selectedItem => {
      selectedItem.selected = item.selected;
      selectedItems.set(
        [
          selectedItem.label,
          !!this.right.countPane.selectableText
            ? selectedItem.value[this.right.countPane.selectableText]
            : selectedItem.value,
        ].join(),
        selectedItem,
      );
    });

    let count = 0;
    selectedItems.forEach(item => {
      if (
        item.type !== MultiSelectItems.iconHeader &&
        item.type !== MultiSelectItems.selectableHeader
      ) {
        count++;
      }
    });

    this.right.countPane.primaryCount = count;
    this.right.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      Array.from(selectedItems.values()),
      dualPaneMultiSelectPrefix + selectedItems.size,
      item,
    );

    if (!!this.right.verifySaveButton) {
      this.right.disable = this.right.verifySaveButton({
        leftView: this.left,
        rightView: this.right,
      } as unknown as DualPaneViewModel);
    }
    this.checkValidity.emit();
  };

  handleSelectAllChange = ($event, searchedItems: SelectableItem[]) => {
    const selected = $event?.detail?.checked;
    const currentSelections = this.right.value ?? [];
    const selectedItems = new Map<string, SelectableItem>();
    currentSelections.forEach(selectedItem => {
      selectedItems.set(
        [
          selectedItem.label,
          !!this.right.countPane.selectableText
            ? selectedItem.value[this.right.countPane.selectableText]
            : selectedItem.value,
        ].join(),
        selectedItem,
      );
    });
    const filteredItems = searchedItems.filter(
      item => item.type !== MultiSelectItems.iconHeader,
    );
    filteredItems.forEach(item => {
      item.selected = selected;
      const keyArray = [item.label, item.subLabel];
      const itemKey = keyArray.join();
      if (selected) {
        selectedItems.set(itemKey, item);
      } else {
        selectedItems.delete(itemKey);
      }
    });
    this.right.countPane.primaryCount = selected ? filteredItems.length : 0;

    this.right.handleItemSelection(
      this.left,
      this.platform,
      this.right,
      Array.from(selectedItems.values()),
      dualPaneMultiSelectPrefix + selectedItems.size,
    );
    this.checkValidity.emit();
  };

  findFirstLetter(customerName: String) {
    const regex = /([a-zA-Z]{1})/;
    return customerName[customerName.search(regex)];
  }
}
