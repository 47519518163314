import { Component, Input, OnInit } from '@angular/core';
import { RecentPurchase } from '@usf/list-types';
import { RecentPurchaseHelper } from '../../../helpers/recent-purchase.helpers';
import { RecentPurchaseRecord } from '../../../models/recent-purchase.record';
import { Observable } from 'rxjs';
import { UserService } from '@app/user/services';
import { UserKinds } from '@usf/user-types/user';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-recent-purchase-popover',
  templateUrl: './recent-purchase-popover.component.html',
  styleUrls: ['./recent-purchase-popover.component.scss'],
})
export class RecentPurchasePopoverComponent implements OnInit {
  @Input() recentPurchase: RecentPurchase;
  userKind$: Observable<UserKinds>;
  readonly userKinds = UserKinds;
  recentPurchaseRecords: RecentPurchaseRecord[] = [];

  constructor(
    private userService: UserService,
    readonly platformService: PlatformService,
  ) {}

  ngOnInit() {
    this.recentPurchaseRecords = RecentPurchaseHelper.formatRecentPurchase(
      this.recentPurchase,
      'MMM DD',
    );
    this.userKind$ = this.userService.userKind$();
  }
}
