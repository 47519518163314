import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  PlatformEnum,
  PlatformService,
  UsabillaService,
} from '@panamax/app-state';
import {
  combineLatest,
  Observable,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { getCustomers, selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, Store } from '@ngrx/store';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { getAllSiteCustomizationProfilesSuccess } from '@app/user/store';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-invite-new-user-modal',
  templateUrl: './invite-new-user-modal.component.html',
  styleUrls: ['./invite-new-user-modal.component.scss'],
})
export class InviteNewUserModalComponent
  implements OnInit, OnDestroy, ViewWillEnter
{
  @Output() selectNewUserEmail = new EventEmitter<any>();
  @Output() selectNewUserAdvOptions = new EventEmitter<any>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  @Output() mobileNewUserInfoSelected = new EventEmitter<boolean>();
  @Output() mobileNewCustomersSelected = new EventEmitter<boolean>();
  @Output() mobileNewUserAdvOptionsOrganizationSelected =
    new EventEmitter<boolean>();
  @Output() mobileNewUserAdvOptionsSiteRoleSelected =
    new EventEmitter<boolean>();
  @Output() mobileNewUserAdvOptionsUserAccessSelected =
    new EventEmitter<boolean>();
  @Output() mobileNewUserAdvOptionsNationalCustomizationSelected =
    new EventEmitter<boolean>();

  SECTIONS = ['userInformation', 'customers'];
  ADV_OPTIONS = ['organization', 'siteRole', 'userAccessOptions'];
  SITE_ROLES = ['none', 'NO_SUBMIT', 'NO_REPORTING'];
  USER_ACCESS_OPTIONS = [
    'massSlDelete',
    'payInvoiceUser',
    'hideOg',
    'loadRecentlyPurchasedInOG',
    // Temporary removing MLM from user access options
    // 'mlmLTO',
  ];
  MAX_CUSTOMERS = 100;

  inviteNewUserForm: FormGroup;

  selectedOption = '';
  searchKey = '';

  customers = [];
  searchedCustomers = [];
  selectedCustomer: Customer;
  nationalCustomizations = [];
  searchedNationalCustomizations = [];

  result: {
    newUserEmail: {
      currentValue: string;
      savedValue: string;
      hasChanges: boolean;
    };
    customers: {
      currentValue: { customerNumber: number; divisionNumber: number }[];
      savedValue: { customerNumber: number; divisionNumber: number }[];
      hasChanges: boolean;
    };
  } = {
    newUserEmail: { currentValue: '', savedValue: '', hasChanges: false },
    customers: { currentValue: [], savedValue: [], hasChanges: false },
  };

  newUserAdvOptions = {
    organization: {
      currentValue: '',
      savedValue: '',
      hasChanges: false,
    },
    siteRole: {
      currentValue: 'none',
      savedValue: 'none',
      hasChanges: false,
    },
    userAccessOptions: {
      currentValue: [],
      savedValue: [],
      hasChanges: false,
    },
    //R4A-5006
    // nationalCustomization: {
    //   currentValue: -1,
    //   savedValue: -1,
    //   hasChanges: false,
    // },
  };

  customers$: Subscription = new Subscription();
  advancedOptionsFlag$: Observable<boolean>;
  nationalCustomizationsPremierFlag$: Observable<boolean>;
  nationalCustomizations$: Subscription = new Subscription();
  public destroy$ = new Subject<void>();

  isLoading = true;
  customersMaxLimitReached = false;
  nationalCustomerSelected = false;
  advancedOptionsFlag = false;
  nationalCustomizationsPremierFlag = false;
  advancedOptionsOpened = false;

  constructor(
    readonly modalController: ModalController,
    public platform: PlatformService,
    public translateService: TranslateService,
    public store: Store,
    public inviteNewUserService: InviteNewUserService,
    private usabillaService: UsabillaService,
    private fb: FormBuilder,
    private actionSubject: ActionsSubject,
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      this.usabillaService.hide();
    }

    this.initForms();
    this.advancedOptionsFlag$ =
      this.inviteNewUserService.advancedOptionsFlag$();
    this.nationalCustomizationsPremierFlag$ =
      this.inviteNewUserService.nationalCustomizationPremierFlag$();
    this.inviteNewUserService.getAllSiteCustomizationProfiles();

    this.advancedOptionsFlag$
      ?.pipe(
        takeUntil(this.destroy$),
        tap(flag => (this.advancedOptionsFlag = flag)),
      )
      .subscribe();

    this.nationalCustomizationsPremierFlag$
      ?.pipe(
        takeUntil(this.destroy$),
        tap(flag => (this.nationalCustomizationsPremierFlag = flag)),
      )
      .subscribe();

    this.selectedOption =
      this.platform.platformType === PlatformEnum.mobile
        ? ''
        : 'userInformation';

    this.customers$ = combineLatest([
      this.store.select(getCustomers),
      this.store.select(selectedCustomer),
    ]).subscribe(([customers, selectedCustomer]) => {
      this.selectedCustomer = selectedCustomer;

      const selectedCustomerData = selectedCustomer
        ? {
            customerNumber: selectedCustomer.customerNumber,
            divisionNumber: selectedCustomer.divisionNumber,
          }
        : null;

      if (!this.result.customers.savedValue.length) {
        this.result.customers.savedValue = [selectedCustomerData];
      }
      if (!this.result.customers.currentValue.length) {
        this.result.customers.currentValue = [selectedCustomerData];
      }

      if (selectedCustomer?.customerType === 'NA') {
        this.nationalCustomerSelected = true;
      }

      this.customers = customers.map(customer => {
        const isSelected = this.result?.customers?.currentValue.some(
          selected =>
            selected?.customerNumber === customer.customerNumber &&
            selected?.divisionNumber === customer.divisionNumber,
        );

        return {
          ...customer,
          selected: isSelected,
        };
      });

      this.searchedCustomers = this.customers;
      this.isLoading = false;
    });

    this.nationalCustomizations$ = this.actionSubject
      .pipe(ofType(getAllSiteCustomizationProfilesSuccess))
      .subscribe(data => {
        let customizations = Array.isArray(data?.customizations)
          ? [...data.customizations]
          : [];

        if (!this.nationalCustomizationsPremierFlag) {
          customizations = customizations.filter(
            c => c.profileName !== 'Premier',
          );
        }

        this.nationalCustomizations = customizations.sort((a, b) => {
          if (a.profileName === 'Default') return -1;
          if (b.profileName === 'Default') return 1;
          return a.profileName.localeCompare(b.profileName);
        });

        this.searchedNationalCustomizations = this.nationalCustomizations;
      });
  }

  public initForms() {
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
    });
  }

  searchCustomer(event: any) {
    this.searchKey = event?.target?.value || '';
    if (this.searchKey !== '') {
      this.searchedCustomers = this.customers
        .filter(
          c =>
            !this.searchKey ||
            c.customerName
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            c.customerNumber.toString().includes(this.searchKey) ||
            c.divisionNumber.toString().includes(this.searchKey),
        )
        .map(customer => {
          const isSelected = this.result?.customers?.currentValue.some(
            selected =>
              selected?.customerNumber === customer.customerNumber &&
              selected?.divisionNumber === customer.divisionNumber,
          );
          return {
            ...customer,
            selected: isSelected,
          };
        });
    } else {
      this.searchedCustomers = this.customers.map(customer => ({
        ...customer,
        selected: this.result?.customers?.currentValue.some(
          selected =>
            selected?.customerNumber === customer.customerNumber &&
            selected?.divisionNumber === customer.divisionNumber,
        ),
      }));
    }
  }

  searchNationalCustomization(event: any) {
    const searchKey = event?.target?.value || '';

    const sortCustomizations = (a, b) => {
      if (a.profileName === 'Default') return -1;
      if (b.profileName === 'Default') return 1;
      return a.profileName.localeCompare(b.profileName);
    };

    if (searchKey !== '') {
      this.searchedNationalCustomizations = this.nationalCustomizations
        .filter(c =>
          c.profileName.toLowerCase().includes(searchKey.toLowerCase()),
        )
        .sort(sortCustomizations);
    } else {
      this.searchedNationalCustomizations =
        this.nationalCustomizations.sort(sortCustomizations);
    }
  }

  isCustomerChecked(customer: any) {
    return this.result?.customers?.currentValue.some(
      c =>
        c.customerNumber === customer.customerNumber &&
        c.divisionNumber === customer.divisionNumber,
    );
  }

  toggleCustomerSelection(customer: any, event: Event): void {
    event.stopPropagation();
    const isSelected = !customer.selected;
    customer.selected = isSelected;

    this.updateCustomersSelected(
      customer.customerNumber,
      customer.divisionNumber,
    );

    if (!Array.isArray(this.result.customers.currentValue)) {
      this.result.customers.currentValue = [];
    }

    this.customers = this.customers.map(c => ({
      ...c,
      selected: this.result?.customers?.currentValue.some(
        selected =>
          selected?.customerNumber === c.customerNumber &&
          selected?.divisionNumber === c.divisionNumber,
      ),
    }));

    this.searchedCustomers = this.searchedCustomers.map(c => ({
      ...c,
      selected: this.result?.customers?.currentValue.some(
        selected =>
          selected?.customerNumber === c.customerNumber &&
          selected?.divisionNumber === c.divisionNumber,
      ),
    }));
  }

  updateCustomersSelected(customerNumber: number, divisionNumber: number) {
    const customerKey = { customerNumber, divisionNumber };
    if (!Array.isArray(this.result.customers.currentValue)) {
      this.result.customers.currentValue = [];
    }
    const newCustomerIndex = this.result?.customers?.currentValue.findIndex(
      c =>
        c?.customerNumber === customerNumber &&
        c?.divisionNumber === divisionNumber,
    );

    if (newCustomerIndex > -1) {
      this.result?.customers?.currentValue?.splice(newCustomerIndex, 1);
    } else {
      if (this.result?.customers?.currentValue?.length >= this.MAX_CUSTOMERS) {
        this.customersMaxLimitReached = true;
        return;
      }
      this.result?.customers?.currentValue?.push(customerKey);
    }

    this.nationalCustomerSelected = this.result?.customers?.currentValue.some(
      selected => {
        const customer = this.customers.find(
          c =>
            c.customerNumber === selected?.customerNumber &&
            c.divisionNumber === selected?.divisionNumber,
        );
        return customer?.customerType === 'NA';
      },
    );

    //R4A-5006
    // if (!this.nationalCustomerSelected) {
    //   this.newUserAdvOptions.nationalCustomization.currentValue = -1;
    //   this.newUserAdvOptions.nationalCustomization.savedValue = -1;
    //   this.newUserAdvOptions.nationalCustomization.hasChanges = false;
    // }

    this.customersMaxLimitReached =
      this.result.customers.currentValue.length >= this.MAX_CUSTOMERS;
    this.selectNewUserEmail.emit({
      newUserEmail: this.result?.newUserEmail?.currentValue,
      customers: this.result?.customers?.currentValue,
    });
  }

  isCustomersSelected(): boolean {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.result?.customers?.savedValue
        : this.result?.customers?.currentValue;
    return options.length > 0;
  }

  getSelectedCustomersLabel(): string {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.result?.customers?.savedValue
        : this.result?.customers?.currentValue;
    const selectedCustomer = options[0];

    return options.length === 1
      ? this.customers.find(
          c =>
            c.customerNumber === selectedCustomer.customerNumber &&
            c.divisionNumber === selectedCustomer.divisionNumber,
        )?.customerName
      : this.translateService.instant('i18n.notifications.selected') +
          ': ' +
          options.length;
  }

  isUserAccessOptionsSelected(): boolean {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.newUserAdvOptions?.userAccessOptions?.savedValue
        : this.newUserAdvOptions?.userAccessOptions?.currentValue;
    return options.length > 0;
  }

  getUserAccessOptionsLabel(): string {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.newUserAdvOptions?.userAccessOptions?.savedValue
        : this.newUserAdvOptions?.userAccessOptions?.currentValue;

    return options.length === 1
      ? this.translateService.instant(
          'i18n.profilePage.inviteNewUser.advancedOptions.' + options[0],
        )
      : this.translateService.instant('i18n.notifications.selected') +
          ': ' +
          options.length;
  }

  getSectionValue(section: string, isAdvOption: boolean): any {
    const sectionData = isAdvOption
      ? this.newUserAdvOptions[section]
      : this.result[section];

    if (section === 'nationalCustomization') {
      return this.platform.platformType === this.platform.platformEnum.mobile
        ? (this.nationalCustomizations.find(
            n => n.profileId === sectionData?.savedValue,
          )?.profileName ?? 'Default')
        : (this.nationalCustomizations.find(
            n => n.profileId === sectionData?.currentValue,
          )?.profileName ?? 'Default');
    } else {
      return this.platform.platformType === this.platform.platformEnum.mobile
        ? sectionData?.savedValue
        : sectionData?.currentValue;
    }
  }

  setSelectedOption(option: string) {
    if (this.platform.platformType === PlatformEnum.mobile) {
      switch (option) {
        case 'userInformation':
          this.mobileNewUserInfoSelected.emit(true);
          break;
        case 'customers':
          this.mobileNewCustomersSelected.emit(true);
          break;
        case 'organization':
          this.mobileNewUserAdvOptionsOrganizationSelected.emit(true);
          break;
        case 'siteRole':
          this.mobileNewUserAdvOptionsSiteRoleSelected.emit(true);
          break;
        case 'userAccessOptions':
          this.mobileNewUserAdvOptionsUserAccessSelected.emit(true);
          break;
        case 'nationalCustomization':
          this.mobileNewUserAdvOptionsNationalCustomizationSelected.emit(true);
          break;
        default:
          break;
      }
    }
    if (option !== 'customers') {
      this.searchedCustomers = this.customers;
    }
    if (
      this.selectedOption === 'nationalCustomization' &&
      option !== 'nationalCustomization'
    ) {
      this.resetSearchNationalCustomization();
    }
    if (this.selectedOption === 'organization') {
      this.newUserAdvOptions.organization.savedValue =
        this.newUserAdvOptions.organization.currentValue;
    }
    this.selectedOption = option;
  }

  resetSearch() {
    this.searchKey = '';
  }

  resetCustomerSearch() {
    const selectedCustomerNumber = this.selectedCustomer?.customerNumber;
    const selectedDivisionNumber = this.selectedCustomer?.divisionNumber;

    this.customers = this.customers.map(customer => {
      const isSelected =
        customer.customerNumber === selectedCustomerNumber &&
        customer.divisionNumber === selectedDivisionNumber;
      return {
        ...customer,
        selected: isSelected ? isSelected : false,
        disabled: false,
      };
    });

    this.searchedCustomers = this.customers.map(customer => ({
      ...customer,
      selected: this.result?.customers?.currentValue.some(
        selected =>
          selected?.customerNumber === customer.customerNumber &&
          selected?.divisionNumber === customer.divisionNumber,
      ),
    }));

    this.result.customers.currentValue =
      selectedCustomerNumber && selectedDivisionNumber
        ? [
            {
              customerNumber: selectedCustomerNumber,
              divisionNumber: selectedDivisionNumber,
            },
          ]
        : [];

    this.customersMaxLimitReached = false;
  }

  resetSearchNationalCustomization() {
    this.searchedNationalCustomizations = this.nationalCustomizations.map(
      customization => ({
        ...customization,
      }),
    );
  }

  clearSelectedOption() {
    this.selectedOption = '';
    this.searchedCustomers = this.customers;
    this.resetSearchNationalCustomization();
  }

  mobileUserInfoSelectedNext() {
    this.setSelectedOption('customers');
  }

  onInviteNewUser() {
    this.result.newUserEmail.currentValue = this.inviteNewUserForm.get(
      'newUserEmailAddress',
    )?.value;
    this.selectNewUserEmail.emit({
      newUserEmail: this.result?.newUserEmail?.currentValue,
      customers: this.result?.customers?.currentValue,
    });
  }

  onOrganizationChange(event: any) {
    const newOrganization = event?.detail?.value;
    this.newUserAdvOptions.organization.currentValue = newOrganization;
    this.emitNewUserAdvOptions();
  }

  onSiteRoleChange(event: any) {
    const newSiteRole = event?.detail?.value;
    this.newUserAdvOptions.siteRole.currentValue = newSiteRole;
    this.newUserAdvOptions.siteRole.hasChanges =
      newSiteRole !== this.newUserAdvOptions?.siteRole?.savedValue;
    this.emitNewUserAdvOptions();
  }

  toggleUserAccessRoleSelection(userAccessOption: any, event: Event): void {
    event.stopPropagation();
    const currentOptions =
      this.newUserAdvOptions.userAccessOptions.currentValue;
    const updatedOptions = currentOptions.includes(userAccessOption)
      ? currentOptions.filter(option => option !== userAccessOption)
      : [...currentOptions, userAccessOption];

    this.newUserAdvOptions.userAccessOptions.currentValue = updatedOptions;
    this.newUserAdvOptions.userAccessOptions.hasChanges =
      JSON.stringify(updatedOptions) !==
      JSON.stringify(this.newUserAdvOptions.userAccessOptions.savedValue);
    this.emitNewUserAdvOptions();
  }

  //R4A-5006
  // onNationalCustomizationChange(event: any) {
  //   const newNationalCustomization = event?.detail?.value;
  //   this.newUserAdvOptions.nationalCustomization.currentValue =
  //     newNationalCustomization;
  //   this.newUserAdvOptions.nationalCustomization.hasChanges =
  //     newNationalCustomization !==
  //     this.newUserAdvOptions.nationalCustomization.savedValue;
  //   this.emitNewUserAdvOptions();
  // }

  emitNewUserAdvOptions() {
    this.selectNewUserAdvOptions.emit({
      organization: this.newUserAdvOptions.organization.currentValue,
      siteRole: this.newUserAdvOptions.siteRole.currentValue,
      userAccessOptions: this.newUserAdvOptions.userAccessOptions.currentValue,
      //R4A-5006
      // nationalCustomization:
      //   this.newUserAdvOptions.nationalCustomization.currentValue,
    });
  }

  updateOptions(newOptions: any) {
    this.newUserAdvOptions = { ...newOptions };
  }

  updateResult(newResults: any) {
    this.result = { ...newResults };
  }

  revertChanges(isUserInfo: boolean) {
    if (!this.result.customers.savedValue.length) {
      this.result.customers.savedValue = [
        ...this.result.customers.currentValue,
      ];
    }

    if (isUserInfo && this.result.newUserEmail.savedValue === '') {
      this.inviteNewUserForm.reset();
    }

    Object.keys(this.newUserAdvOptions).forEach(key => {
      this.newUserAdvOptions[key].currentValue =
        this.newUserAdvOptions[key].savedValue;
      this.newUserAdvOptions[key].hasChanges = false;
    });

    Object.keys(this.result).forEach(key => {
      if (Array.isArray(this.result[key].savedValue)) {
        this.result[key].currentValue = [...this.result[key].savedValue];
      } else {
        this.result[key].currentValue = this.result[key].savedValue;
      }
      this.result[key].hasChanges = false;
    });

    this.inviteNewUserForm.setValue({
      newUserEmailAddress: this.result.newUserEmail.currentValue,
    });

    const selectedCustomerNumber = this.selectedCustomer?.customerNumber;
    const selectedDivisionNumber = this.selectedCustomer?.divisionNumber;
    const isSelectedCustomerSaved = this.result.customers.savedValue.some(
      c =>
        c.customerNumber === selectedCustomerNumber &&
        c.divisionNumber === selectedDivisionNumber,
    );

    this.customers = this.customers.map(customer => {
      const isSelected =
        this.result.customers.currentValue.some(
          selected =>
            selected?.customerNumber === customer.customerNumber &&
            selected?.divisionNumber === customer.divisionNumber,
        ) ||
        (isSelectedCustomerSaved &&
          customer.customerNumber === selectedCustomerNumber &&
          customer.divisionNumber === selectedDivisionNumber);

      return { ...customer, selected: isSelected };
    });

    this.searchedCustomers = this.searchedCustomers.map(customer => {
      const isSelected =
        this.result.customers.currentValue.some(
          selected =>
            selected?.customerNumber === customer.customerNumber &&
            selected?.divisionNumber === customer.divisionNumber,
        ) ||
        (isSelectedCustomerSaved &&
          customer.customerNumber === selectedCustomerNumber &&
          customer.divisionNumber === selectedDivisionNumber);

      return { ...customer, selected: isSelected };
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.customers$.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.resetSearch();
  }

  trackByCustomer(index: number, customer: any): number {
    return customer.customerNumber;
  }
}
