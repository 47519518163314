export enum DualPaneView {
  noView,
  // left side view
  basicList,
  checkbox,
  // right side views
  searchableItemRadio,
  simpleInputOrMessage,
  filePicker,
  calendar,
  selectableCountableRadio,
  multiSelectCheckbox,
  searchableInventoryRadio,
  searchableProductRadio,
  multiSelectInventory,
  multiInput,
}

// for varying style - w or w/o checkbox, letter icon
export enum MultiSelectItems {
  basic,
  iconHeader,
  subItem,
  iconBasic,
  selectableHeader,
}

export enum MultiInputItems {
  blank,
  label,
  input,
  percentageRange,
  range,
  rangeLabel,
  currencyInput,
  negativeCurrency,
  iconLabel,
}
