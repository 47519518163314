import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PanAppState,
  TokenAuthMode,
  UsfTokenService,
} from '@panamax/app-state';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { FEATURES } from '@shared/constants/splitio-features';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: UsfTokenService,
    private translateService: TranslateService,
    readonly panAppState: PanAppState,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const traceContext = localStorage.getItem('trace-context'); //check on this

    let requestHeaders = {
      'consumer-id': 'ecom',
      'correlation-id': 'ecomr4-' + uuidv4(),
      'transaction-id': Date.now().toString(),
      // 'trace-context': traceContext
    };

    if (req.url.startsWith('https://sdk.split.io/api')) {
      return next.handle(req);
    }

    if (!this.needsCredentials(req.url)) {
      return next.handle(
        req.clone({
          setHeaders: {
            ...requestHeaders,
          },
        }),
      );
    }

    return from(
      Promise.all([
        this.tokenService.authorize(TokenAuthMode.Local),
        this.panAppState.featureEnabled(
          FEATURES.split_global_get_localized_content,
        ),
      ]),
    ).pipe(
      switchMap(([token, isGetLocalizedContentEnabled]) => {
        const usfLangHeader =
          isGetLocalizedContentEnabled && !!this.translateService.currentLang
            ? { USFLang: this.translateService.currentLang }
            : {};
        const newRequest = req.clone({
          setHeaders: {
            ...requestHeaders,
            Authorization: `Bearer ${token}`,
            ...usfLangHeader,
          },
        });
        return next.handle(newRequest);
      }),
    );
  }

  //TODO: look into using HttpContextToken for this
  private needsCredentials(url: string): boolean {
    if (
      !url.match('http[s]*://') ||
      url.match(`${environment.powerReviewsUrl}`)
    ) {
      return false;
    }
    if (url.indexOf('/token') > -1 && !url.includes('search')) {
      return false;
    }
    if (url.indexOf('/minimumVersions') > -1) {
      return false;
    }
    return true;
  }
}
