export enum OrderAnalyticsMessages {
  handPricingModalOpenEvent = 'modal open',
  handPricingValueChangeEvent = 'hand pricing submit',
  capability = 'orders',
  cart = 'cart',
  productDiscoveryCapability = 'product discovery',
  pageName = 'us foods:r4:order',
  modalName = 'hand pricing',
  didYouForgetClick = 'did you forget click',
  inspiredPicksClick = 'inspired picks click',
  addToOrder = 'add to order',
  didYouForget = 'did you forget',
  inspiredPicks = 'inspired picks',
  topSellersReviewOrder = 'top sellers review order',
  trendingNowReviewOrder = 'trending now review order',
  searchSubmit = 'search submit',
  searchForSubs = 'search for subs',
  loadOrderPageName = 'us foods:r4:view order',
  pageLoadedEvent = 'page loaded',
  buyMoreAndSaveClick = 'promo banner click',
  directBuyPromoId = 'direct buy more and save',
  jumpToDirectClick = 'jump to direct',
  buttonClick = 'button click',
  copyOrder = 'copy order',
  cancelSubmittedOrderEvent = 'cancel order',
  cancelInProgressOrderEvent = 'cancel order',
  sharePrintDownloadEvent = 'share print download',
  printFileType = 'print:order',
  quickOrderEntryEvent = 'add to order',
  quickOrderEntryAddMethod = 'quick order entry',
  confirmQuantiyException = 'confirm quantity exception',
  exceptions = 'exceptions',
  updateQuantity = 'update quantity',
  uqe = 'UQE',
  topSellers = 'top sellers review order',
  trendingNow = 'trending now review order',
  topSellerClick = 'top sellers click',
  trendingNowClick = 'trending now click',
  productDiscovery = 'product discovery',
  featureLoaded = 'feature loaded',
  featureEngagement = 'feature engagement',
  frequentlyBoughtTogetherDisplayed = 'frequently bought together displayed',
  frequentlyBoughtTogetherView = 'frequently bought together carousel view',
  qoePageName = 'us foods:r4:quick order entry',
  orderInsightsPageName = 'us foods:r4:order insights',
  orderInsightsAddMethod = 'order insight',
  orderInsightType = 'dyf product',
  removeFromOrder = 'remove from order',
  shareCapability = 'alerts',
  shareViaEmailModalOpen = 'modal open',
  shareViewEmailReviewOrder = 'share review order',
  shareViewEmailFileType = 'share:reviewOrder:email',
}
