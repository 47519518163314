import { Dictionary } from '@ngrx/entity';
import {
  createSelector,
  createSelectorFactory,
  resultMemoize,
} from '@ngrx/store';
import {
  selectAllFeaturesEnabled,
  selectOnline,
  selectPlatformType,
  selectSession,
} from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { ListDataForProduct } from '@shared/models/list-data-for-product';
import { Customer } from '@usf/customer-types';
import {
  ListItemProduct,
  RecentPurchaseState,
  listItemProductMap,
  masterListItemProductMap,
  recentPurchaseAdapter,
  selectRecentPurchaseState,
} from '@usf/ngrx-list';
import { MListItemProduct } from '@usf/ngrx-list/lib/reducers/master-list-item/master-list-item.reducer';
import {
  CART_SELECTORS,
  CartState,
  NextDeliveryDate,
  OrderItemState,
  nextDeliverySelectors,
  orderItemSelectors,
  partnerSelectors,
} from '@usf/ngrx-order';
import {
  ProductPricingDetailState,
  selectProductPricingEntities,
} from '@usf/ngrx-pricing';
import {
  BetterBuysState,
  CustomerPillState,
  IMslProductState,
  LoadingState,
  isSupplierOutOfStock,
  selectCompletedProductSummaries,
  selectCustomerPillEntities,
  selectMslProductState,
  selectProductContractEntities,
  selectProductInventoryEntities,
  selectProductScoopEntities,
  selectProductSummaryEntities,
} from '@usf/ngrx-product';
import { ProductContractState } from '@usf/ngrx-product/lib/models/product-contract-state.model';
import { ProductInventoryState } from '@usf/ngrx-product/lib/models/product-inventory-state.model';
import { ProductSummaryState } from '@usf/ngrx-product/lib/models/product-summary-state.model';
import { IPromotionState, selectPromotionState } from '@usf/ngrx-promotions';
import { selectedCustomer } from '../../ngrx-customer/store';
import { getProductAlternative } from '../../product-detail/utils/product-alternative.util';
import { calculateSubFlags } from '../../product-detail/utils/product-substitute.util';
import {
  forceOutOfStockOrderProduct,
  forceRegularOrderProduct,
  isMSLRestrictionOverrideY,
  selectUserProfiles,
} from '../../user/store';
import {
  customerIsGLCustomer,
  customerIsMslRestricted,
  customerIsOgRestricted,
} from '../helpers/customer.helpers';
import { AppStateForProduct } from '../models/app-state-for-product';
import { Product } from '../models/product.model';
import {
  buildSummary,
  canOrderProduct,
  checkElementWasFound,
  enrichProduct,
  enrichProductAlternative,
  getAllListProductProperties,
  isHideOutOfStock,
  mapProductPropertiesToTrackingProperties,
  shouldShowSplitCard,
} from './helpers/product-info.selectors.helper';
import { ProductPropertiesEnum } from '@usf/product-types';
import { PlatformType } from '@panamax/app-state/lib/models/platform.model';
import { SUBSTITUTE_TYPE } from '../../product-detail/constants/constants';
import { SessionState } from '@panamax/app-state/lib/models/session.model';
import { ProductPricing } from '@usf/price-types';
import { selectBetterBuysWithSavingData } from 'src/app/better-buys/selectors/better-buys-selectors';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { extractPoDates } from '@product-detail/utils/po-dates-util';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';

// TODO: replace orderGuideFlag with order guide split flag when established.
// Exporting to be used for Inventory logic
export const appStateForProductsSelector = createSelector(
  selectedCustomer,
  selectPlatformType(),
  selectOnline(),
  selectAllFeaturesEnabled([FEATURES.split_global_master_lists]),
  selectAllFeaturesEnabled([FEATURES.split_global_master_lists]),
  selectAllFeaturesEnabled([FEATURES.split_global_og_msl_subs]),
  isMSLRestrictionOverrideY,
  selectAllFeaturesEnabled([FEATURES.split_global_works_well_with_flag]),
  forceRegularOrderProduct,
  forceOutOfStockOrderProduct,
  selectAllFeaturesEnabled([FEATURES.split_global_direct_allow_returns]),
  (
    selectedCustomer: Customer,
    platformType: PlatformType,
    isOnline: boolean,
    masterListFeatureFlag: boolean,
    orderGuideFeatureFlag: boolean,
    subsFeatureFlag: boolean,
    isMSLRestrictionOverrideY,
    isWorksWellWithEnabled: boolean,
    forceRegularOrderProduct: boolean,
    forceOutOfStockOrderProduct: boolean,
    directAllowReturnsFlag: boolean,
  ): AppStateForProduct => {
    return {
      isMslRestricted: customerIsMslRestricted(selectedCustomer),
      isOgRestricted: customerIsOgRestricted(selectedCustomer),
      isGlOwner: customerIsGLCustomer(selectedCustomer),
      subsCase: calculateSubFlags(
        selectedCustomer?.restrictToOG,
        selectedCustomer?.substitutionAllowed,
      ),
      masterListFeatureFlag,
      orderGuideFeatureFlag,
      subsFeatureFlag,
      platformType,
      isOnline,
      isMSLRestrictionOverrideY,
      isWorksWellWithEnabled,
      isForceRegularOrderProduct: forceRegularOrderProduct,
      isHideInventoryOutOfStockYellowModule: forceOutOfStockOrderProduct,
      directAllowReturnsFlag,
    };
  },
);

// Exporting for use by Inventory creation
export const listDataForProduct = createSelector(
  listItemProductMap(),
  masterListItemProductMap(),
  recentPurchaseAdapter.getSelectors(selectRecentPurchaseState).selectEntities,
  (
    listItemProductMap: Dictionary<ListItemProduct>,
    masterListItemProductMap: Dictionary<MListItemProduct>,
    recentPurchaseDict: Dictionary<RecentPurchaseState>,
  ): ListDataForProduct => {
    return {
      listItemProductMap,
      masterListItemProductMap,
      recentPurchaseDict,
    };
  },
);

const productMemoizeFn = projectorFn =>
  resultMemoize(projectorFn, baseProductComparator);

const baseProductComparator = (curr, next) => {
  if (curr?.size !== next?.size) {
    return false;
  }
  // same size
  const allMatch = Array.from(curr.entries()).every(([key, currentState]) => {
    const nextState = next.get(key);
    if (!nextState) {
      return false;
    }
    if (
      currentState?.summary?.updateDtm !== nextState.summary?.updateDtm ||
      currentState?.inventory?.updateDtm !== nextState.inventory?.updateDtm ||
      currentState?.contract?.updateDtm !== nextState.contract?.updateDtm ||
      currentState?.contract?.iconId !== nextState.contract?.iconId
    ) {
      return false;
    }
    return true;
  });

  if (allMatch) {
    return true;
  } else {
    return false;
  }
};

/**
 * Use only if consuming product data from new /products endpoint. Using
 * ProductService.loadProductNew or other 'new' methods.
 */
export const productSelector = createSelectorFactory(productMemoizeFn)(
  selectCompletedProductSummaries,
  selectProductInventoryEntities,
  selectProductContractEntities,
  selectCustomerPillEntities,
  selectMslProductState,
  appStateForProductsSelector,
  (
    summaries: ProductSummaryState[],
    inventories: ProductInventoryState[],
    contracts: ProductContractState[],
    customerPills: CustomerPillState[],
    mslProductState: IMslProductState,
    appStateForProduct: AppStateForProduct,
  ) => {
    const map = summaries.reduce((map, summaryState) => {
      const summary = checkElementWasFound(summaryState);
      const inventory = checkElementWasFound(
        inventories[summaryState.productNumber],
      );
      const contract = checkElementWasFound(
        contracts[summaryState.productNumber],
      );
      const customerPill = checkElementWasFound(
        customerPills[summaryState.productNumber],
      );
      const mslProduct = mslProductState?.entities[summaryState?.productNumber];
      const masterListProductsAreLoaded = appStateForProduct?.isMslRestricted
        ? mslProductState?.loaded
        : true;

      if (
        summary !== undefined &&
        inventory !== undefined &&
        contract !== undefined &&
        customerPill !== undefined &&
        masterListProductsAreLoaded
      ) {
        return map.set(summaryState.productNumber, {
          productNumber: summaryState.productNumber,
          summary: {
            ...summary,
            properties: new Set(summary?.propertiesArray ?? []),
            additionalProperties: new Set(),
          },
          inventory,
          contract,
          customerPill,
          notFound:
            summaryState.loadingState === LoadingState.notFound ||
            summaryState.loadingState === LoadingState.error,
          mslProduct: mslProduct,
        });
      } else {
        return map;
      }
    }, new Map<number, Product>());
    return map;
  },
);

export const productWithAlternativesSelector = createSelector(
  productSelector,
  selectProductPricingEntities,
  orderItemSelectors.selectOrderItemState,
  selectPromotionState,
  appStateForProductsSelector,
  listDataForProduct,
  state => selectBetterBuysWithSavingData(state),
  selectedCustomer,
  selectSession(),
  partnerSelectors.selectPartner,
  nextDeliverySelectors.selectNextDeliveryDetails,
  CART_SELECTORS.selectCart,
  (
    productsMap: Map<number, Product>,
    pricing: Dictionary<ProductPricingDetailState>,
    orderItemState: OrderItemState,
    promotionState: IPromotionState,
    appStateForProduct: AppStateForProduct,
    listDataForProduct: ListDataForProduct,
    betterBuys: Dictionary<BetterBuysState>,
    selectedCustomer: Customer,
    currentSession: SessionState,
    partnerState: PartnerState,
    nextDeliveryDate: NextDeliveryDate,
    cartState: CartState,
  ) => {
    const map = Array.from(productsMap.entries()).reduce(
      (map, [productNumber, product]) => {
        if (product === null) {
          return map.set(productNumber, null);
        }

        const hideOutOfStock = isHideOutOfStock(
          product,
          appStateForProduct,
          selectedCustomer,
          partnerState,
          nextDeliveryDate,
          cartState,
          extractPoDates(product?.summary?.poDates),
        );

        const alternative = getProductAlternative(
          product,
          productsMap,
          appStateForProduct,
          listDataForProduct,
          hideOutOfStock,
        );

        // enrich base product
        enrichProduct(
          product,
          productsMap,
          pricing,
          orderItemState,
          promotionState,
          listDataForProduct,
          appStateForProduct,
          betterBuys,
          selectedCustomer,
          currentSession.isGuest,
          partnerState,
          nextDeliveryDate,
          cartState,
          alternative?.prioritizeAlternative,
        );

        // Check alternative is defined
        if (alternative === undefined) {
          // Ignore this product until alternative has been defined
          return map.set(product.productNumber, {
            ...product,
          });
        }
        const updateProduct = {
          ...product,
          alternative,
        } as Product;

        // enrich alternative product
        if (shouldShowSplitCard(updateProduct, appStateForProduct)) {
          enrichProductAlternative(
            updateProduct,
            productsMap,
            pricing,
            orderItemState,
            promotionState,
            listDataForProduct,
            appStateForProduct,
            selectedCustomer,
            currentSession.isGuest,
          );
        }
        return map.set(product.productNumber, updateProduct);
      },
      new Map<number, Product>(),
    );
    return map;
  },
  // Look for alternative
);

export const productByIdSelector = productNumber =>
  createSelector(productWithAlternativesSelector, summaries =>
    summaries.get(productNumber),
  );

export const baseProductsByIds = (productNumbers: number[]) =>
  createSelector(
    selectProductSummaryEntities,
    selectProductInventoryEntities,
    selectProductContractEntities,
    selectCustomerPillEntities,
    (summaries, inventories, contracts, customerPills) => {
      const map: Map<number, Product> = new Map();
      productNumbers.forEach(productNumber => {
        const summary = checkElementWasFound(summaries[productNumber]);
        const inventory = checkElementWasFound(inventories[productNumber]);
        const contract = checkElementWasFound(contracts[productNumber]);
        const customerPill = checkElementWasFound(customerPills[productNumber]);
        if (
          summary !== undefined &&
          inventory !== undefined &&
          contract !== undefined &&
          customerPill !== undefined
        ) {
          map.set(productNumber, {
            productNumber,
            summary,
            contract,
            customerPill,
            inventory,
            notFound:
              summary.loadingState === LoadingState.notFound ||
              summary.loadingState === LoadingState.error,
          });
        }
      });
      return map;
    },
  );

export const productSelectorByIds = (productNumbers: number[]) =>
  createSelector(
    baseProductsByIds(productNumbers),
    selectUserProfiles,
    selectProductScoopEntities,
    appStateForProductsSelector,
    listDataForProduct,
    (
      baseProductsMap: Map<number, Product>,
      profiles,
      scoops,
      appStateForProduct: AppStateForProduct,
      listDataForProduct: ListDataForProduct,
    ) => {
      const map = Array.from(baseProductsMap.entries()).reduce(
        (map, [productNumber, baseProduct]) => {
          if (
            baseProduct !== undefined &&
            profiles !== undefined &&
            scoops !== undefined
          ) {
            const summary = buildSummary(baseProduct, scoops);

            return map.set(productNumber, {
              ...baseProduct,
              summary: {
                ...summary,
              },
              orderable: canOrderProduct(
                { ...baseProduct, summary },
                baseProductsMap,
                appStateForProduct,
                listDataForProduct,
              ),
            });
          }
          return map;
        },
        new Map<number, Product>(),
      );
      return map;
    },
  );

export const productByIdSelectorWithSelectedCustomer = productNumber =>
  createSelector(
    productWithAlternativesSelector,
    selectedCustomer,
    (products, selectedCustomer) => ({
      ...products.get(productNumber),
      selectedCustomer,
      resultRank: 0,
    }),
  );

export const selectProductAttributesByIds = (productNumbers: number[]) =>
  createSelector(
    selectProductSummaryEntities,
    selectProductInventoryEntities,
    selectProductContractEntities,
    selectCustomerPillEntities,
    listDataForProduct,
    selectPromotionState,
    (
      summaries: Dictionary<ProductSummaryState>,
      inventories: Dictionary<ProductInventoryState>,
      contracts: Dictionary<ProductContractState>,
      customerPills: Dictionary<CustomerPillState>,
      listDataForProduct: ListDataForProduct,
      promotionState: IPromotionState,
    ): Map<number, Product> => {
      const productAttributeMap = new Map<number, Product>();
      for (const productNumber of productNumbers) {
        const summary = summaries[productNumber];
        const inventory = inventories[productNumber];
        const contract = contracts[productNumber];
        const customerPill = customerPills[productNumber];
        const promotion = promotionState?.entities[productNumber];
        const summaryProperties =
          summary?.propertiesArray ?? summary?.properties;

        const allProperties = new Set(summaryProperties ?? []);
        if (isSupplierOutOfStock(inventory)) {
          allProperties.add(ProductPropertiesEnum.supplierOutOfStock);
        }
        if (contract && allProperties.has(ProductPropertiesEnum.contract)) {
          allProperties.add(ProductPropertiesEnum.contract);
        }
        const listProductProperties = getAllListProductProperties(
          productNumber,
          listDataForProduct,
        );
        listProductProperties.forEach(listProductProperty => {
          allProperties.add(listProductProperty);
        });
        const trackingAttributes = mapProductPropertiesToTrackingProperties(
          allProperties,
          !!promotion?.promoId && `deal:${promotion.promoId}`,
        );
        productAttributeMap.set(productNumber, {
          summary,
          inventory,
          contract,
          customerPill,
          productNumber,
          trackingAttributes,
        });
      }
      return productAttributeMap;
    },
  );

export const selectAllMslSubsituteProductNumbers = createSelector(
  selectProductSummaryEntities,
  selectProductInventoryEntities,
  selectMslProductState,
  appStateForProductsSelector,
  listDataForProduct,
  (
    summaries: Dictionary<ProductSummaryState>,
    inventories: Dictionary<ProductInventoryState>,
    mslProductState: IMslProductState,
    appStateForProduct: AppStateForProduct,
    listDataForProduct: ListDataForProduct,
  ) => {
    // Create map of products
    const productsMap = new Map<number, Product>();
    for (const key in summaries) {
      const summary = summaries[key];
      const inventory = inventories[key];
      const mslProduct = mslProductState.entities[key];
      let product = {
        productNumber: summary?.productNumber,
        summary: { ...summary },
        inventory: { ...inventory },
        mslProduct,
        contract: {} as ProductContractState,
      } as Product;
      productsMap.set(summary?.productNumber, product);
    }
    // Loop through map and get each products alternative and check if its a msl sub
    const masterListSubs: number[] = [];
    for (const key of productsMap?.keys()) {
      let product = productsMap.get(key);
      const alternative = getProductAlternative(
        product,
        productsMap,
        appStateForProduct,
        listDataForProduct,
      );
      if (
        alternative?.substituteInfo?.substituteType === SUBSTITUTE_TYPE.mslSub
      ) {
        masterListSubs.push(alternative?.product?.productNumber);
      }
    }
    return masterListSubs;
  },
);

// TODO: Move to ngrx pricing library at some point
export const isPricingLoaded = (productNumbers: number[]) =>
  createSelector(
    selectProductPricingEntities,
    (pricingEntities: Dictionary<ProductPricingDetailState>) => {
      let pricesAreLoaded = true;
      for (const productNumber of productNumbers) {
        const productPricing = pricingEntities[productNumber];
        if (
          productPricing?.loadingState !== LoadingState.loaded &&
          productPricing?.loadingState !== LoadingState.error &&
          productPricing?.loadingState !== LoadingState.notFound
        ) {
          pricesAreLoaded = false;
          break;
        }
      }
      return pricesAreLoaded;
    },
  );

export const getFilteredProductsSelector = (
  productNumbers: number[],
  filterType: ProductFilteringEnum,
) =>
  createSelector(
    selectedCustomer,
    productWithAlternativesSelector,
    (selectedCustomer, products) => {
      const selectedProducts = productNumbers.map(productNumber =>
        products.get(productNumber),
      );

      if (selectedProducts.some(product => !product)) {
        return undefined;
      }

      return selectedProducts.filter(
        product =>
          !!product &&
          !product.notFound &&
          ProductFilter.applyFilter(product, selectedCustomer, filterType),
      );
    },
  );
