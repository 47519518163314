import { Component, Input, OnInit } from '@angular/core';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { IonNav, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformEnum } from '@panamax/app-state';
import { MultiSelectItems } from '@shared/constants/dual-pane.enum';
import { SelectableItem } from '@shared/models/dual-pane-model';

@Component({
  selector: 'app-verify-copied-products-modal',
  templateUrl: './verify-copied-products-modal.component.html',
  styleUrls: ['./verify-copied-products-modal.component.scss'],
})
export class VerifyCopiedProductsModalComponent implements ViewWillEnter {
  @Input() platform: PlatformEnum;
  @Input() dismissFunc: Function;
  title: string;
  @Input() selectedProducts: ProductRow[];
  @Input() selectedLists: SelectableItem[];
  @Input() finalSubmission: Function;

  platformRef = PlatformEnum;
  MultiSelectItemsRef = MultiSelectItems;

  constructor(
    private ionNav: IonNav,
    readonly translateService: TranslateService,
  ) {}
  ionViewWillEnter(): void {
    this.title =
      this.selectedProducts.length > 1
        ? this.translateService.instant(
            'i18n.lists.verifyProductsModal.title',
            { count: this.selectedProducts.length },
          )
        : this.translateService.instant(
            'i18n.lists.verifyProductsModal.altTitle',
          );
  }

  dismissModal() {
    this.dismissFunc();
  }

  goBack() {
    this.ionNav.pop();
  }
  submit() {
    this.finalSubmission(
      this.selectedLists
        .filter(item => item.type === MultiSelectItems.subItem)
        .map(item => item.value),
      this.selectedProducts.map(item => item.productNumber),
    );
    this.dismissFunc();
  }
}
