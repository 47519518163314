<ng-container
  *ngIf="right?.searchPane?.searchableObservable$ | async as searchableProducts"
>
  <ion-label
    [attr.data-cy]="dataCyTag + '-seaarch-title'"
    *ngIf="!!right?.searchPane?.searchTitle"
    class="subtitle search-title"
  >
    {{ right?.searchPane?.searchTitle | translate }}
  </ion-label>
  <ion-searchbar
    [attr.data-cy]="dataCyTag + '-search-bar'"
    class="search"
    mode="md"
    placeholder="{{ right?.searchPane?.searchPlaceholder | translate }}"
    [(ngModel)]="right.searchPane.searchKey"
    (ionClear)="resetSearch()"
    (ionInput)="handleSearch()"
  ></ion-searchbar>
  <div
    data-cy="no-results-found"
    class="no-results-found"
    *ngIf="searchableProducts?.length === 0"
  >
    {{
      right?.searchPane?.noResultsOverride ??
        'i18n.lists.copyListModal.noResultsFound'
        | translate
        | translate
    }}
  </div>
  <ion-list
    [ngClass]="
      platform === platformRef.desktop
        ? 'desktop'
        : platform === platformRef.tablet
          ? 'tablet'
          : 'mobile'
    "
  >
    <ion-item-divider *ngIf="searchableProducts?.length > 0"></ion-item-divider>
    <ion-radio-group value="{{ right?.selectedItem }}">
      <app-selectable-product
        *ngFor="let product of searchableProducts"
        [product]="product"
        (selectProductEmitter)="productSelected($event)"
      ></app-selectable-product>
    </ion-radio-group>
  </ion-list>
</ng-container>
