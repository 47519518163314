import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListDetailManagementService } from '../../../list-detail-management/services/list-detail-management.service';
import { SelectedProductsBaseComponent } from '../../selected-products-base.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-group-list-input',
  templateUrl: './group-list-input.component.html',
  styleUrls: ['./group-list-input.component.scss'],
})
export class GroupListInputComponent extends SelectedProductsBaseComponent {
  title = '';

  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }
}
