<ng-container
  *ngIf="
    right?.searchPane?.searchableObservable$ | async as searchableInventories
  "
>
  <div class="flex-container">
    <ion-label
      [attr.data-cy]="dataCyTag + '-seaarch-title'"
      *ngIf="!!right?.searchPane?.searchTitle"
      class="subtitle search-title"
    >
      {{ right?.searchPane?.searchTitle | translate }}
    </ion-label>
    <ion-searchbar
      [attr.data-cy]="dataCyTag + '-search-bar'"
      class="search"
      mode="md"
      placeholder="{{ right?.searchPane?.searchPlaceholder | translate }}"
      [(ngModel)]="right.searchPane.searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="handleSearch()"
    ></ion-searchbar>

    <div
      data-cy="no-results-found"
      class="no-results-found"
      *ngIf="searchableInventories?.length === 0"
    >
      {{
        right?.searchPane?.noResultsOverride ??
          'i18n.lists.copyListModal.noResultsFound' | translate
      }}
    </div>
    <ion-list>
      <ion-radio-group value="{{ right?.selectedItem }}">
        <app-radio-btn-inventory
          *ngFor="let inventory of searchableInventories; let i = index"
          [inventory]="inventory"
          [index]="i"
          (selectInventoryEmitter)="inventorySelected($event)"
        ></app-radio-btn-inventory>
      </ion-radio-group>
    </ion-list>
  </div>
</ng-container>
