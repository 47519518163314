import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiUpdatable } from '../../models/api-updatable';
import { QuantityUpdate } from '../../models/quantity-update';
import { PlatformService, PlatformEnum, PanAppState } from '@panamax/app-state';
import { IonInput } from '@ionic/angular';
import { take } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
})
export class QuantityInputComponent {
  @Input() productNumber: number;
  @Input() quantity: ApiUpdatable<number>;
  @Input() isEaches: boolean;
  @Input() isDisabled: boolean;
  @Input() isOffline: boolean;
  @Input() platform: PlatformEnum;
  @Input() index: number;
  @Input() id: string;
  @Input() isProductCard = false;
  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();
  @Output() quantityElementEmitter = new EventEmitter<IonInput>();
  @ViewChild('itemInput') itemInputBox: IonInput;

  constructor(
    readonly platformService: PlatformService,
    private panAppState: PanAppState,
  ) {}

  changeQuantity(productNumber: number, event$: any) {
    const newQuantity = Number(event$?.currentTarget?.value);
    const noChanges = this.quantityDidNotChange(newQuantity);
    if (
      newQuantity < 0 ||
      newQuantity > 999 ||
      isNaN(newQuantity) ||
      !productNumber ||
      this.isDisabled ||
      noChanges
    ) {
      return;
    }
    // When offline and the feature is disabled we should not allow the quantity to be updated
    this.panAppState
      .feature$([FEATURES.split_global_offline_update_quantities])
      .pipe(take(1))
      .subscribe(feature => {
        if (!feature) {
          if (this.isOffline) {
            if (event$ && event$.currentTarget) {
              event$.currentTarget.value = this.quantity.previousValue;
            }
          }
        }
      });

    this.quantityUpdateEmitter.emit({
      productNumber,
      quantity: newQuantity,
      isEaches: this.isEaches,
    });
  }

  decrementQuantity(productNumber: number, value: string | number) {
    const numvalue = +value;
    if (numvalue <= 0 || !productNumber || this.isDisabled) {
      return;
    }
    const updatedValue = numvalue - 1;
    this.quantityUpdateEmitter.emit({
      productNumber,
      quantity: updatedValue,
      isEaches: this.isEaches,
    });
  }

  incrementQuantity(productNumber: number, value: string | number) {
    const numvalue = +value;
    if (numvalue >= 999 || !productNumber || this.isDisabled) {
      return;
    }
    const updatedValue = numvalue + 1;
    this.quantityUpdateEmitter.emit({
      productNumber,
      quantity: updatedValue,
      isEaches: this.isEaches,
    });
  }
  onFocus($event: any) {
    this.quantityElementEmitter.emit($event.srcElement);
  }
  quantityDidNotChange(newQuantity: number) {
    return (
      newQuantity === this.quantity?.currentValue ||
      (!this.quantity?.currentValue && newQuantity === 0)
    );
  }
}
