import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { CoveoToken } from '../../models/coveo/coveo-token.model';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  map,
  of,
  Subscription,
} from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
import { buildSearchEngine, SearchEngine } from '@coveo/headless';
import { COVEO_TOKEN_LOCAL_STORAGE_KEY } from '@shared/constants/coveo-constants';
import { ToggleNewSearchService } from '../toggle-new-search/toggle-new-search.service';
import { SessionState } from '@panamax/app-state/lib/models/session.model';
import { buildCommerceEngine, CommerceEngine } from '@coveo/headless/commerce';

@Injectable({
  providedIn: 'root',
})
export class CoveoService {
  constructor(
    private panAppState: PanAppState,
    private toggleNewSearchService: ToggleNewSearchService,
    private http: HttpClient,
  ) {}

  coveoTokenSubscription$: Subscription;
  previousSession: SessionState;
  private coveoSearchEngine: SearchEngine;
  private coveoCommerceEngine: CommerceEngine;
  searchEngineSubject$: BehaviorSubject<SearchEngine> = new BehaviorSubject(
    null,
  );
  commerceEngineSubject$: BehaviorSubject<CommerceEngine> = new BehaviorSubject(
    null,
  );

  getCoveoToken$() {
    return this.http.get<CoveoToken>(`${environment.coveoTokenUrl}`).pipe(
      map(coveoResponse => {
        localStorage.setItem(
          COVEO_TOKEN_LOCAL_STORAGE_KEY,
          coveoResponse.coveoToken,
        );
        this.initializeCommerceEngine(coveoResponse.coveoToken);
        return coveoResponse.coveoToken;
      }),
      catchError(err => of(err)),
    );
  }

  init() {
    this.coveoTokenSubscription$ = combineLatest([
      this.panAppState.session$,
      this.toggleNewSearchService.isNewSearchEnabled(),
      this.panAppState.feature$([
        FEATURES.split_global_search_new_search_analytics,
      ]),
    ])
      .pipe(
        filter(
          ([_, newSearchEnabled, newSearchAnalyticsFlag]) =>
            newSearchEnabled || newSearchAnalyticsFlag,
        ),
        concatMap(([session]) => {
          if (
            !this.previousSession ||
            session?.selectedCustomerId !==
              this.previousSession?.selectedCustomerId
          ) {
            this.previousSession = session;
            return this.getCoveoToken$();
          }

          if (localStorage.getItem(COVEO_TOKEN_LOCAL_STORAGE_KEY) === null) {
            return this.getCoveoToken$();
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  teardown() {
    this.coveoTokenSubscription$?.unsubscribe();
    localStorage.removeItem(COVEO_TOKEN_LOCAL_STORAGE_KEY);
  }

  initializeSearchEngine(coveoToken: string) {
    this.coveoSearchEngine = buildSearchEngine({
      configuration: {
        accessToken: coveoToken,
        organizationId: `${environment.coveoOrganizationId}`,
      },
    });
    this.searchEngineSubject$.next(this.coveoSearchEngine);
  }

  initializeCommerceEngine(coveoToken: string) {
    this.coveoCommerceEngine = buildCommerceEngine({
      configuration: {
        accessToken: coveoToken,
        organizationId: `${environment.coveoOrganizationId}`,
        analytics: { enabled: false },
        context: {
          country: 'US',
          currency: 'USD',
          language: 'en',
          view: { url: 'url' },
        },
      },
    });
    this.commerceEngineSubject$.next(this.coveoCommerceEngine);
  }

  getSearchEngine$() {
    return this.searchEngineSubject$;
  }

  getCommerceEngine$() {
    return this.commerceEngineSubject$;
  }
}
