import { Component, Input } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-better-buy-savings',
  templateUrl: './better-buy-savings.component.html',
  styleUrls: ['./better-buy-savings.component.scss'],
})
export class BetterBuySavingsComponent {
  @Input() savings: number;
  @Input() savingsUom: string;
  @Input() savingsPerCase: number;
  @Input() annualSavings: number;
  @Input() annualSavingsUom: string;
  @Input() pricingAvailable: boolean;
  @Input() flexDirection: string;
  @Input() platformType: PlatformEnum;

  readonly platformEnum = PlatformEnum;

  constructor() {}
}
