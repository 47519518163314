import { Customer } from '@usf/customer-types';
import {
  ListGroupState,
  ListItemState,
  ListSearchSortFilterOptions,
  ListState,
  MasterListItemProductState,
} from '@usf/ngrx-list';
import { OrderHeader, Tallies } from '@usf/ngrx-order';
import { UserProfile } from '@usf/user-types/user-profile';
import { Product } from '../../../../shared/models/product.model';
import { RecentPurchaseProductData } from '../../../pages/recently-purchased/model/recently-purchased-view.model';
import { ImportErrorDetail, ListGroupKey } from '@usf/list-types';
import { LoadingState } from '@usf/ngrx-product';

// eslint-disable-next-line no-shadow
export enum ItemTypes {
  sort = 'sort',
  group = 'group',
  product = 'product',
  showAllGroups = 'show all groups',
  justALabel = 'just a label',
}

export interface ListGroupsAndItems {
  list: ListState;
  groups: ListGroupState[];
  listItems: ListItemState[];
  selectedGroup: ListGroupState;
  unassignedGroup: ListGroupState;
  numProductsSelected: number;
  numExternalProductsSelected: number;
  groupNames: string[];
}

export interface GroupRow {
  itemType: string;
  groupName: string;
  itemCount: number;
  listGroupKey?: ListGroupKey;
}

export interface ProductRow extends Product {
  itemType?: string;
  item?: ListItemState;
  groupName?: string;
  groupId?: string;
  expanded?: boolean;
  isChecked?: boolean;
  selected?: boolean;
  disabled?: boolean;
  sequenceNumber?: number;
  groupSequenceNumber?: number;
  listId?: string;
  // Master list fields
  masterListItem?: MasterListItemProductState;
  isPrimaryProduct?: boolean;
  hasAlternatesToShow?: boolean;
  isLastSecondary?: boolean; // is used for displaying divider after final alternate product
}

export type ListRow = GroupRow | ProductRow | RecentPurchaseProductData;

export interface ListDetailViewModel {
  selectedCustomer: Customer;
  userProfiles: UserProfile[];
  orderItemTallies: Tallies;
  orderHeader: OrderHeader;
  list: ListState;
  listGroups: ListGroupState[];
  listItems: ListItemState[];
  items: ListRow[]; // will be either group name item or product row
  itemHeights: number[];
  productLoadingState: LoadingState;
  productCardIds?: Set<string>;
  productClasses: string[];
  groupNames: string[];
  showEllipsis?: boolean;
  downloadPrintFlag?: boolean;
  multipleProductFlag?: boolean;
  orderMinimumMetFeatureFlag?: boolean;
  listItemNoteFeatureFlag?: boolean;
  importErrors?: { errors: ImportErrorDetail[] };
  listSearchSortFilterOptions: ListSearchSortFilterOptions;
  sortAndFilterCount?: number;
}

export interface ListManagementViewModel {
  selectedCustomer: Customer;
  userProfiles: UserProfile[];
  list: ListState;
  items: ListRow[]; // will be either group name item or product row
  managementSidebarGroups: GroupRow[];
  numProductsSelected: number;
  selectedGroup: ListGroupState;
  unassignedGroup: ListGroupState;
  listGroups: ListGroupState[];
  listItems: ListItemState[];
  itemHeights: number[];
  downloadPrintFlag?: boolean;
  listItemNoteFeatureFlag?: boolean;
  listSearchSortFilterOptions: ListSearchSortFilterOptions;
  multipleProductFlag?: boolean;
}
