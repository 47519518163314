import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';
import { PersonalizationData } from '@panamax/app-state/lib/models/tracking.model';

export const handPricingModalOpen: AnalyticsTransformer = (
  tracking: TrackingData,
) => ({
  event: tracking.data.event,
  capability: tracking.data.capability,
  products: tracking.data.products,
  modal: {
    name: tracking.data.modalName,
  },
});

export const handPricingValueChange: AnalyticsTransformer = (
  tracking: TrackingData,
) => ({
  event: tracking.data.event,
  capability: tracking.data.capability,
  products: tracking.data.products,
});

export const shareViaEmailReviewOrderOpenModal = (tracking: TrackingData) => {
  const { capability, event, modalName } = tracking.data;

  return {
    capability,
    event,
    modal: {
      name: modalName,
    },
  };
};

export const shareViaEmailReviewOrder = (tracking: TrackingData) => {
  const { capability, event, fileType } = tracking.data;

  return {
    capability,
    event,
    file: {
      type: fileType,
    },
  };
};

/**
 * DEFAULT TRANFORMER
 *
 * @param tracking
 * @returns Data Layer when no change is needed
 */
export const defaultTransformer = (tracking: TrackingData) => ({
  ...tracking.data,
});

/**
 * ORDER PAGE LOAD
 *
 * @param tracking
 * @returns Data Layer needed for tracking the Order Page Load
 */
export const orderPageLoad = (tracking: TrackingData) => ({
  ...tracking.data,
});

/**
 * CART/ORDER UPDATE PERSONALIZATION
 * @param personalizationData tracking for personalization events
 * @returns personalization layer for tracking cart changes
 */
export const orderPersonalizationTransformer = (
  personalizationData: PersonalizationData,
) => {
  const orderState = personalizationData?.resultState['cart'];
  const orderItems = orderState?.orderItems;
  const orderHeader = orderState?.orderHeader;
  let order = [];
  if (!!orderItems && !!orderHeader) {
    orderItems.forEach(orderItem => {
      const productId = `${orderHeader?.divisionNumber}-${orderItem.productNumber}`;
      if (orderItem.eachesOrdered?.currentValue > 0) {
        order.push({
          productId,
          quantity: orderItem.eachesOrdered?.currentValue,
          unitPrice:
            personalizationData?.resultState['pricing']?.entities[
              orderItem.productNumber
            ]?.eachPrice,
          currency: 'USD',
        });
      }

      if (orderItem.unitsOrdered?.currentValue > 0) {
        order.push({
          productId,
          quantity: orderItem.unitsOrdered?.currentValue,
          unitPrice:
            personalizationData?.resultState['pricing']?.entities[
              orderItem.productNumber
            ]?.unitPrice,
          currency: 'USD',
        });
      }
    });
  }

  return {
    personalization: {
      events: [['addCartRows', order]],
    },
  };
};
