export enum ListColumns {
  customerProductNumber,
  caseCount,
  eachAndCasePrice,
  eachCount,
  groupName,
  lineNumber,
  onHand,
  productBrand,
  productDescription,
  productNote,
  productNumber,
  productPackageSize,
  productPrice,
  productPriceWithZero,
  productStatus,
  productType,
  productUom,
  storageDescription,
  usFoodsClassDescription,
  imageStatus,
  imageType,
  netWeight,
  catchWeightFlag,
  breakable,
}

export enum productConversionColumns {
  isOriginal,
  productNumber,
  productDescription,
  productBrand,
  productPackageSize,
  casePrice,
  eachPrice,
  caseUOM,
  eachUOM,
  portionPrice,
  statusCode,
  productStatus,
  updatedLists,
  mlmProduct,
  storageCode,
  usfStorage,
  netWeight,
  contractIcon,
  local,
  jit,
  direct,
  customerPill,
  classNum,
  classDesc,
  categoryNum,
  categoryDesc,
  groupNum,
  groupDesc,
  servingSize,
  ServingUOM,
  servingPerTrade,
  calories,
  caloriesFat,
  totalFat,
  satFat,
  transFat,
  cholest,
  sodium,
  totalCarb,
  dietFiber,
  totalSug,
  protein,
  alCrust,
  alEgg,
  alFish,
  alMilk,
  alPeanuts,
  alSoy,
  alTreeNut,
  alWheat,
  alMust,
  childNutrition,
  cholestFree,
  fatFree,
  lowFat,
  msgFree,
  noSug,
  transFatFree,
  localSource,
  kosher,
  halal,
  recycle,
  certBeef,
  organic,
  noAddedHorm,
  addInfo,
  ingStatement,
  productStatusImage,
  productPackageSizeForPDF,
  productType,
  productNote,
  eachAndCasePrice,
  pdfOriginality,
}

export enum nonUsfProductColunms {
  productDescription,
  productNumber,
  vendor,
  brand,
  manufacturerNumber,
  packSize,
  avgNetWeight,
  price,
  priceUom,
  unitDescription,
  unitsPerCase,
  glCode,
}

export enum InventoryColumns {
  lineNumber,
  glCode,
  groupName,
  productNumber,
  productDescription,
  productStatus,
  productType,
  productBrand,
  productPackageSize,
  unitDescription,
  unitByCase,
  caseCount,
  eachCount,
  casePrice,
  productCost,
  productUom,
  extendedCost,
  imageType,
  imageStatus,
  vendor,
  unitPrice,
  freehandCount,
  firstCasePrice,
  firstEachPrice,
  secondCasePrice,
  secondEachPrice,
  casePriceChange,
  casePricePercentChange,
  inventoryOnePrices,
  inventoryTwoPrices,
  casePriceUom,
  unitPriceUom,
  casePriceWithoutUom,
  unitPriceWithoutUom,
}

export enum PaymentActivityColumns {
  customerName,
  customerNumber,
  documentDate,
  primaryTransactionNumber,
  primaryTransactionType,
  secondaryTransactionNumber,
  secondaryTransactionType,
  referenceNumber,
  paymentMethod,
  paidAmount,
  paymentDate,
  access,
}

export enum AllInvoicesColumns {
  customerName,
  customerNumber,
  primaryTransactionType,
  primaryTransactionNumber,
  secondaryTransactionType,
  secondaryTransactionNumber,
  customerPO,
  dateIssued,
  amount,
  amountDue,
  dueDate,
  status,
}

export interface ImageIconPair {
  icon: string;
  image: HTMLImageElement;
}

export interface ProductRowsAndNeededImages {
  productRowsByGroup: string[][][];
  imageKeys: Set<string>;
}
