import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss', '../../dual-pane-shared.scss'],
})
export class FilePickerComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() dataCyTag = 'flie-picker';
  @Input() platform: PlatformEnum;
  @Input() error: any;
  @Input() file: any;
  @Input() showSpinner: boolean;
  @Input() showChangeButton = false;

  processingData: boolean;
  badData: boolean;
  showDrag: boolean;

  @Output() fileAddEvent = new EventEmitter<void>();
  @Output() fileDeleteEvent = new EventEmitter<void>();
  @Output() updateFileEvent = new EventEmitter<File>();
  @Output() processFileEvent = new EventEmitter<File>();
  constructor() {}

  async onDeleteFile() {
    this.fileDeleteEvent.emit();
  }

  simulateDrop = () => {
    const data = this.fileInput.nativeElement.files;
    this.handleFiles(data);
  };

  onAddFile = async () => {
    this.fileAddEvent.emit();
  };

  handleFiles = async (files: FileList) => {
    const showMultipleFileError = files.length > 1;
    if (showMultipleFileError) {
      this.updateFileEvent.emit(undefined);
      return;
    }
    const file = files[0];
    this.processFileEvent.emit(file);
  };

  onDrop = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.showDrag = false;
    this.handleFiles(event.dataTransfer.files ?? []);
  };

  onChange = async (event: any) => {
    this.handleFiles(event?.target?.files ?? []);
  };

  dragging = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.showDrag = true;
  };

  clearDrag = (event: any) => {
    event.preventDefault();
    this.showDrag = false;
  };

  enterDrag = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.showDrag = true;
  };
}
