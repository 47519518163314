import { BetterBuysState } from '@usf/ngrx-product';
import { ProductSummaryState } from '@usf/ngrx-product/lib/models/product-summary-state.model';
import { ProductPricing } from '@usf/price-types';

export const getAnnualSavings = (
  originalProductSummary: ProductSummaryState,
  betterBuyProductSummary: ProductSummaryState,
  originalProductPrice: ProductPricing,
  betterBuyProductPrice: ProductPricing,
  betterBuy: BetterBuysState,
): BetterBuysState => {
  let updatedBetterBuy = { ...betterBuy };

  if (
    (originalProductPrice &&
      originalProductPrice.unitPrice &&
      !Number(originalProductPrice.unitPrice)) ||
    (betterBuyProductPrice &&
      betterBuyProductPrice.unitPrice &&
      !Number(betterBuyProductPrice.unitPrice))
  ) {
    updatedBetterBuy.annualSavings = null;
    updatedBetterBuy.annualSavingsUom = null;
    updatedBetterBuy.savings = null;
    updatedBetterBuy.savingsUom = null;
    updatedBetterBuy.savingsPerPound = null;
    updatedBetterBuy.savingsPerCase = null;
    updatedBetterBuy.savingsPerServing = null;
    updatedBetterBuy.annualSavingsBySavingsPerPound = null;
    updatedBetterBuy.annualSavingsBySavingsPerCase = null;
    updatedBetterBuy.annualSavingsBySavingsPerServing = null;
    updatedBetterBuy.savingsPerPortion = null;
    updatedBetterBuy.savingsPerPortionUom = null;
    updatedBetterBuy.annualSavingsBySavingsPerPortion = null;
    updatedBetterBuy.annualSavingsBySavingsPerPortionUom = null;
    updatedBetterBuy.savingsPerCaseBasedOnPortion = null;
    updatedBetterBuy.savingsPerCaseBasedOnServing = null;
  } else {
    // calculate the savings and annual savings information

    //if packsize is different calculating price per portion savings
    let savingsPerPortion = 0;
    let savingsPerPortionUom = null;
    let savingsPerCaseBasedOnPortion = 0;
    let annualSavingsBySavingsPerPortion = 0;
    if (
      originalProductSummary?.salesPackSize !==
        betterBuyProductSummary?.salesPackSize &&
      originalProductPrice?.priceUom === betterBuyProductPrice?.priceUom &&
      updatedBetterBuy?.originalProductPricePerPortion > 0 &&
      updatedBetterBuy?.betterBuyProductPricePerPortion > 0 &&
      originalProductSummary?.standardComparisonUOM ===
        betterBuyProductSummary?.standardComparisonUOM
    ) {
      savingsPerPortion = getSavingsPerPortion(
        updatedBetterBuy?.originalProductPricePerPortion,
        updatedBetterBuy?.betterBuyProductPricePerPortion,
      );
      if (savingsPerPortion > 0) {
        savingsPerPortionUom = originalProductSummary?.standardComparisonUOM;
        savingsPerCaseBasedOnPortion = getSavingsPerCaseForSavingsPerPortion(
          savingsPerPortion,
          betterBuyProductSummary?.standardComparisonValue,
        );

        annualSavingsBySavingsPerPortion = getAnnualSavingsPerPortion(
          savingsPerCaseBasedOnPortion,
          updatedBetterBuy.annualUsageCase,
        );
      }
    }

    const savingsPerPound = getSavingsPerPound(
      originalProductSummary?.salesPackSize,
      betterBuyProductSummary?.salesPackSize,
      originalProductSummary?.priceUom,
      betterBuyProductSummary?.priceUom,
      originalProductPrice?.unitPrice,
      betterBuyProductPrice?.unitPrice,
      originalProductSummary?.netWeight,
      betterBuyProductSummary?.netWeight,
    );

    const savingsPerCase = getSavingsPerCase(
      originalProductSummary?.salesPackSize,
      betterBuyProductSummary?.salesPackSize,
      betterBuyProductSummary?.netWeight,
      savingsPerPound,
    );

    const savingsPerServing = getSavingsPerServing(
      originalProductPrice?.unitPrice,
      betterBuyProductPrice?.unitPrice,
      betterBuy?.oldProductServingCase,
      betterBuy?.newProductServingCase,
    );

    const savingsPerCaseBasedOnServing = getSavingsPerCaseForSavingsPerServing(
      savingsPerServing,
      betterBuy?.oldProductServingCase,
    );

    const annualSavingsBySavingsPerPound = getAnnualSavingsPerPounds(
      betterBuy?.annualUsagePounds,
      betterBuy?.annualUsageCase,
      savingsPerPound,
      originalProductSummary?.netWeight,
    );

    const annualSavingsBySavingsPerServing = getAnnualSavingsPerServing(
      betterBuy?.annualUsageCase,
      betterBuy?.oldProductServingCase,
      savingsPerServing,
    );

    const annualSavingsBySavingsPerCase = getAnnualSavingsPerCase(
      betterBuy?.annualUsagePounds,
      betterBuy?.annualUsageCase,
      savingsPerCase,
      originalProductSummary?.netWeight,
    );
    updatedBetterBuy = {
      ...betterBuy,
      savingsPerPound,
      savingsPerCase,
      savingsPerServing,
      annualSavingsBySavingsPerPound,
      annualSavingsBySavingsPerCase,
      annualSavingsBySavingsPerServing,
      savingsPerPortion,
      savingsPerPortionUom,
      annualSavingsBySavingsPerPortion,
      savingsPerCaseBasedOnPortion,
      savingsPerCaseBasedOnServing,
    };
    // based on product info and available savings data, record annual savings for this betterBuy
    if (
      originalProductSummary?.catchWeightFlag ||
      betterBuyProductSummary?.catchWeightFlag
    ) {
      updatedBetterBuy.annualSavings = annualSavingsBySavingsPerPound;
      updatedBetterBuy.annualSavingsUom = 'LB';
      updatedBetterBuy.savings = savingsPerPound;
      updatedBetterBuy.savingsUom = 'LB';
    } else if (savingsPerServing > 0) {
      updatedBetterBuy.annualSavings = annualSavingsBySavingsPerServing;
      updatedBetterBuy.annualSavingsUom = 'SV';
      updatedBetterBuy.savings = savingsPerServing;
      updatedBetterBuy.savingsUom = 'SV';
      updatedBetterBuy.savingsPerCase = savingsPerCaseBasedOnServing;
    } else if (savingsPerPortion > 0) {
      updatedBetterBuy.annualSavings = annualSavingsBySavingsPerPortion;
      updatedBetterBuy.annualSavingsUom = savingsPerPortionUom;
      updatedBetterBuy.savings = savingsPerPortion;
      updatedBetterBuy.savingsUom = savingsPerPortionUom;
      updatedBetterBuy.savingsPerCase = savingsPerCaseBasedOnPortion;
    } else if (savingsPerCase > 0) {
      updatedBetterBuy.annualSavings = annualSavingsBySavingsPerCase;
      updatedBetterBuy.annualSavingsUom = 'CS';
      updatedBetterBuy.savingsPerCase = savingsPerCase;
      updatedBetterBuy.savingsUom = 'CS';
    } else {
      updatedBetterBuy.annualSavings = null;
      updatedBetterBuy.annualSavingsUom = null;
      updatedBetterBuy.savings = null;
      updatedBetterBuy.savingsUom = null;
      updatedBetterBuy.savingsPerCase = null;
    }
  }
  return updatedBetterBuy;
};

export const getSavingsPerServing = (
  originalProductUnitPrice: number,
  betterBuyProductUnitPrice: number,
  oldProductServingCase: number,
  newProductServingCase: number,
): number => {
  // calculate pricePerServing for each product, if their unitPrice and serving case are available
  const oldProductPricePerServing =
    originalProductUnitPrice && oldProductServingCase
      ? originalProductUnitPrice / oldProductServingCase
      : 0;
  const newProductPricePerServing =
    originalProductUnitPrice && newProductServingCase
      ? betterBuyProductUnitPrice / newProductServingCase
      : 0;

  // take the difference of the two pricePerServing to get savings
  const savingsPerServing =
    oldProductPricePerServing - newProductPricePerServing;

  // record up to 2 decimal places and check/prevent recording negative savings
  return savingsPerServing > 0 ? roundSavings(savingsPerServing) : 0;
};

export const getAnnualSavingsPerPounds = (
  annualUsagePounds: number,
  annualUsageCase: number,
  savingsPerPound: number,
  originalProductNetWeight: number,
): number => {
  let annualSavingsBySavingsPerPound: number;

  // if annualUsagePounds is available, use it for the calculation
  if (annualUsagePounds) {
    annualSavingsBySavingsPerPound = annualUsagePounds * savingsPerPound;
  } else if (annualUsageCase) {
    // or if only annualUsageCase is available, use it for the calculation
    annualSavingsBySavingsPerPound =
      annualUsageCase * originalProductNetWeight * savingsPerPound;
  } else {
    // if neither are available, assign savings to 0
    annualSavingsBySavingsPerPound = 0;
  }

  // record up to 2 decimal places and check/prevent recording negative savings
  return annualSavingsBySavingsPerPound > 0
    ? roundSavings(annualSavingsBySavingsPerPound)
    : 0;
};

export const getAnnualSavingsPerServing = (
  annualUsageCase: number,
  oldProductServingCase: number,
  savingsPerServing: number,
): number => {
  let annualSavingsBySavingsPerServing: number;

  // if annualUsageCase is available, use it for the calculation. Otherwise assign savings to 0.
  if (annualUsageCase) {
    annualSavingsBySavingsPerServing =
      annualUsageCase * oldProductServingCase * savingsPerServing;
  } else {
    annualSavingsBySavingsPerServing = 0;
  }

  // record up to 2 decimal places and check/prevent recording negative savings
  return annualSavingsBySavingsPerServing > 0
    ? roundSavings(annualSavingsBySavingsPerServing)
    : 0;
};

export const getAnnualSavingsPerCase = (
  annualUsagePounds: number,
  annualUsageCase: number,
  savingsPerCase: number,
  originalProductNetWeight: number,
): number => {
  let annualSavingsBySavingsPerCase: number;

  // if annualUsageCase is available, use it for the calculation
  if (annualUsageCase) {
    annualSavingsBySavingsPerCase = annualUsageCase * savingsPerCase;
  } else if (annualUsagePounds) {
    // or if only annualUsagePounds is available, use it for the calculation
    annualSavingsBySavingsPerCase =
      annualUsagePounds * originalProductNetWeight * savingsPerCase;
  } else {
    // if neither data is available, assign savings to 0
    annualSavingsBySavingsPerCase = 0;
  }

  // record up to 2 decimal places and check/prevent recording negative savings
  return annualSavingsBySavingsPerCase > 0
    ? roundSavings(annualSavingsBySavingsPerCase)
    : 0;
};

export const getSavingsPerPortion = (
  originalProductPricePerPortion: number,
  betterBuyProductPricePerPortion: number,
): number => {
  const savingsPerPortion =
    originalProductPricePerPortion - betterBuyProductPricePerPortion;
  return savingsPerPortion > 0 ? roundSavings(savingsPerPortion) : 0;
};

export const getAnnualSavingsPerPortion = (
  savingsPerCaseBasedOnPortion: number,
  annualUsageCase: number,
): number => {
  const annualSavingsPerPortion =
    annualUsageCase * savingsPerCaseBasedOnPortion;
  return annualSavingsPerPortion > 0
    ? roundSavings(annualSavingsPerPortion)
    : 0;
};

export const getSavingsPerCase = (
  originalProductSalesPackSize: string,
  betterBuyProductSalesPackSize: string,
  betterBuyProductNetWeight: number,
  savingsPerPound: number,
): number => {
  let savingsPerCase: number;

  // if salesPackSizes are different and netweight is available, calculate savingsPerCase
  if (
    originalProductSalesPackSize !== betterBuyProductSalesPackSize &&
    betterBuyProductNetWeight
  ) {
    savingsPerCase = savingsPerPound * betterBuyProductNetWeight;
  } else {
    // otherwise record savingsPerCase to be same as savingsPerPound
    savingsPerCase = savingsPerPound;
  }

  // record up to 2 decimal places and check/prevent recording negative savings
  return savingsPerCase > 0 ? roundSavings(savingsPerCase) : 0;
};

export const getSavingsPerPound = (
  originalProductSalesPackSize: string,
  betterBuyProductSalesPackSize: string,
  originalProductPriceUom: string,
  betterBuyProductPriceUom: string,
  originalProductUnitPrice: number,
  betterBuyProductUnitPrice: number,
  originalProductNetWeight: number,
  betterBuyProductNetWeight: number,
): number => {
  let oldProductPricePerPound: number;
  let newProductPricePerPound: number;
  let savingsPerPound: number;

  // if products have different salesPackSizes, evaluate how to recalculate each product's pricePerPound
  if (originalProductSalesPackSize !== betterBuyProductSalesPackSize) {
    if (originalProductPriceUom == 'LB') {
      oldProductPricePerPound = originalProductUnitPrice;
    } else if (originalProductUnitPrice && originalProductNetWeight) {
      oldProductPricePerPound = roundSavings(
        originalProductUnitPrice / originalProductNetWeight,
      );
    } else {
      oldProductPricePerPound = 0;
    }

    if (betterBuyProductPriceUom == 'LB') {
      newProductPricePerPound = betterBuyProductUnitPrice;
    } else if (betterBuyProductUnitPrice && betterBuyProductNetWeight) {
      newProductPricePerPound = roundSavings(
        betterBuyProductUnitPrice / betterBuyProductNetWeight,
      );
    } else {
      newProductPricePerPound = 0;
    }
  } else {
    // otherwise if salesPackSizes are same, record unit price as pricePerPound
    oldProductPricePerPound = originalProductUnitPrice;
    newProductPricePerPound = betterBuyProductUnitPrice;
  }

  // calculate difference of prices per pound to record savings, or if invalid calc record 0
  if (oldProductPricePerPound && newProductPricePerPound) {
    savingsPerPound = oldProductPricePerPound - newProductPricePerPound;
  } else {
    savingsPerPound = 0;
  }

  // record up to 2 decimal places and check/prevent recording negative savings
  return savingsPerPound > 0 ? roundSavings(savingsPerPound) : 0;
};

export const getSavingsPerCaseForSavingsPerPortion = (
  savingsPerPortion: number,
  standardComparisonValue: string,
): number => {
  return savingsPerPortion * Number(standardComparisonValue);
};

export const getSavingsPerCaseForSavingsPerServing = (
  savingsPerServing: number,
  oldProductServingCase: number,
): number => {
  if (savingsPerServing > 0 && oldProductServingCase > 0) {
    return savingsPerServing * oldProductServingCase;
  }
  return null;
};

const roundSavings = (savings: number): number => {
  return Number(savings.toFixed(4));
};
