import { ListConstants } from '@usf/ngrx-list';
import { ProductRow } from '../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { MasterListHeights } from '../../shared/constants/lists-constants';
import { ProductTypes } from '../../shared/constants/product-type.enum';
import {
  UsfProductCardDesktopHeights,
  UsfProductCardTabletHeights,
  UsfProductCardMobileHeights,
  UsfBetterBuyCardHeights,
  UsfNoQtyBoxWithLineNumberHeights,
} from '../../shared/models/usf-product-card.model';
import { PlatformEnum } from '@panamax/app-state';

export const calculateDesktopProductHeight = (
  product: ProductRow,
  listTypeId: string,
): number => {
  let height = 0;
  if (product?.hasAlternative) {
    height += calculateSplitDesktopProductHeight(product, listTypeId);
  } else {
    height += calculateBaseDesktopProductHeight(product);
  }
  height += calculateMasterListLabelHeight(product);
  if (!!product?.betterBuy && !product?.hasAlternative) {
    height += calculateDesktopBetterBuysHeight(product);
  }
  if (!!product?.item?.forcedUpdate && !product?.betterBuy) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }
  return height;
};

export const calculateBaseDesktopProductHeight = (
  product: ProductRow,
): number => {
  let height = UsfProductCardDesktopHeights.baseHeight;
  height += UsfProductCardDesktopHeights.lineNumberHeight;
  if (product?.productTags?.length > 0) {
    height += UsfProductCardDesktopHeights.tagsWithLineNumberHeight;
  }
  if (product?.drawerMsg?.message || product.notFound) {
    height += UsfProductCardDesktopHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfProductCardDesktopHeights.warningBorderHeight;
  }
  return height;
};

export const calculateSplitDesktopProductHeight = (
  product: ProductRow,
  listTypeId: string,
): number => {
  let height = UsfProductCardDesktopHeights.baseSplitHeight;
  height += UsfProductCardDesktopHeights.splitLineNumberHeight;
  if (product?.promotionDetails) {
    height += UsfProductCardDesktopHeights.splitPromotionsHeight;
  }
  if (product?.drawerMsg?.message) {
    height += UsfProductCardDesktopHeights.drawerHeight;
    if (product?.drawerMsg.isWarning) {
      height += UsfProductCardDesktopHeights.splitWarningBorderHeight;
    }
  }
  if (listTypeId === ListConstants.normalListTypeId) {
    height += UsfProductCardDesktopHeights.noteHeight;
  }
  return height;
};

export const calculateTabletProductHeight = (product: ProductRow): number => {
  let height = 0;
  if (product?.hasAlternative) {
    height += calculateSplitTabletProductHeight(product);
  } else {
    height += calculateBaseTabletProductHeight(product);
  }
  height += calculateMasterListLabelHeight(product);
  if (!!product?.betterBuy && !product?.hasAlternative) {
    height += calculateTabletBetterBuysHeight(product);
  }
  if (product?.notFound) {
    return UsfProductCardTabletHeights.notFoundListDetailHeight;
  }
  if (!!product?.item?.forcedUpdate && !product?.betterBuy) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }
  return height;
};

export const calculateBaseTabletProductHeight = (
  product: ProductRow,
): number => {
  let height = UsfProductCardTabletHeights.baseHeight;
  height += UsfProductCardTabletHeights.lineNumberHeight;
  if (product?.customerProductNumber) {
    height += UsfProductCardTabletHeights.customerProductNumberHeight;
  }
  if (product?.productTags?.length > 0) {
    height += UsfProductCardTabletHeights.tagsWithLineNumberHeight;
  }
  if (product?.drawerMsg?.message) {
    height += UsfProductCardTabletHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfProductCardTabletHeights.warningBorderHeight;
  }
  return height;
};

export const calculateSplitTabletProductHeight = (
  product: ProductRow,
): number => {
  let height = UsfProductCardTabletHeights.baseSplitHeight;
  height += UsfProductCardTabletHeights.splitLineNumberHeight;
  if (product?.customerProductNumber) {
    height += UsfProductCardTabletHeights.splitCustomerProductNumberHeight;
  }
  if (product?.alternative?.product?.productTags?.length > 0) {
    height += UsfProductCardTabletHeights.alternativeTagsHeight;
  }
  if (product?.drawerMsg?.message) {
    height += UsfProductCardTabletHeights.drawerHeight;
  }
  if (product?.productType === ProductTypes.stat0OutOfStock) {
    height += UsfProductCardTabletHeights.stat0OutOfStockQtyBoxesHeight;
  }
  return height;
};

export const calculateMobileProductHeight = (
  product: ProductRow,
  listTypeId: string,
): number => {
  let height = 0;
  if (product?.hasAlternative) {
    height += calculateSplitMobileProductHeight(product, listTypeId);
  } else {
    height += calculateBaseMobileProductHeight(product, listTypeId);
  }
  height += calculateMasterListLabelHeight(product);
  if (!!product?.betterBuy && !product?.hasAlternative) {
    height += calculateMobileBetterBuysHeight(product);
  }
  if (product?.notFound) {
    return UsfProductCardMobileHeights.notFoundListDetailHeight;
  }
  if (!!product?.item?.forcedUpdate && !product?.betterBuy) {
    height += UsfBetterBuyCardHeights.drawerHeight;
  }
  return height;
};

export const calculateBaseMobileProductHeight = (
  product: ProductRow,
  listTypeId: string,
): number => {
  let height = UsfProductCardMobileHeights.baseHeight;
  height += UsfProductCardMobileHeights.lineNumberHeight;
  if (product?.customerProductNumber) {
    height += UsfProductCardMobileHeights.customerProductNumberHeight;
  }
  if (product?.productTags?.length > 0) {
    height += UsfProductCardMobileHeights.tagsHeight;
  }
  if (product?.drawerMsg?.message) {
    height += UsfProductCardMobileHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfProductCardMobileHeights.warningBorderHeight;
  }
  if (product?.promotionDetails) {
    height += UsfProductCardMobileHeights.promotionsHeight;
  }
  if (listTypeId === ListConstants.normalListTypeId) {
    height += UsfProductCardMobileHeights.noteHeight;
  }
  return height;
};

export const calculateSplitMobileProductHeight = (
  product: ProductRow,
  listTypeId: string,
): number => {
  let height = UsfProductCardMobileHeights.baseSplitHeight;
  height += UsfProductCardMobileHeights.splitLineNumberHeight;
  if (product?.customerProductNumber) {
    height += UsfProductCardMobileHeights.splitCustomerProductNumberHeight;
  }
  if (product?.alternative?.product?.productTags?.length > 0) {
    height += UsfProductCardMobileHeights.alternativeTagsHeight;
  }
  if (product?.drawerMsg?.message) {
    height += UsfProductCardMobileHeights.drawerHeight;
  }
  if (product?.promotionDetails) {
    height += UsfProductCardMobileHeights.promotionsHeight;
  }
  if (product?.alternative?.product?.promotionDetails) {
    height += UsfProductCardMobileHeights.promotionsHeight;
  }
  if (product?.productType === ProductTypes.stat0OutOfStock) {
    height += UsfProductCardMobileHeights.stat0OutOfStockQtyBoxesHeight;
  }
  if (listTypeId === ListConstants.normalListTypeId) {
    height += UsfProductCardMobileHeights.noteHeight;
  }
  return height;
};

export const calculateMasterListLabelHeight = (product: ProductRow): number => {
  let height = 0;
  if (product?.masterListItem?.isShowingAlternates) {
    height += MasterListHeights.primaryProductLabel;
    height += MasterListHeights.alternateProductLabel;
  }
  if (product?.isLastSecondary) {
    height += MasterListHeights.lastSecondaryProductDivider;
  }
  return height;
};

export const calculateDesktopBetterBuysHeight = (
  product: ProductRow,
): number => {
  const betterBuyProduct = product?.betterBuy?.product;
  const isBreakable = betterBuyProduct?.summary?.breakable;
  const hasTagsAndSavingsRow =
    betterBuyProduct?.productTags?.length > 0 &&
    (product?.betterBuy?.annualSavings > 0 || product?.betterBuy?.savings > 0);
  return (
    UsfBetterBuyCardHeights.drawerHeight +
    (isBreakable
      ? UsfBetterBuyCardHeights.desktopTwoQtyBoxesHeight
      : hasTagsAndSavingsRow
        ? UsfBetterBuyCardHeights.desktopOneQtyBoxOneAdditionalRowHeight
        : UsfBetterBuyCardHeights.desktopOneQtyBoxHeight)
  );
};

export const calculateTabletBetterBuysHeight = (
  product: ProductRow,
): number => {
  let height =
    UsfBetterBuyCardHeights.touchBaseHeight +
    UsfBetterBuyCardHeights.drawerHeight;
  const productTagsRowExists =
    product?.betterBuy?.product?.productTags?.length > 0;

  if (productTagsRowExists) {
    height += UsfBetterBuyCardHeights.touchAdditionalRowHeight;
  }
  if (
    product?.betterBuy?.savings > 0 ||
    product?.betterBuy?.annualSavings > 0 ||
    product?.betterBuy?.savingsPerCase > 0
  ) {
    height += UsfBetterBuyCardHeights.savingsBoxHeight;
  }
  return height;
};

export const calculateMobileBetterBuysHeight = (
  product: ProductRow,
): number => {
  let height =
    UsfBetterBuyCardHeights.mobileBaseHeight +
    UsfBetterBuyCardHeights.drawerHeight;
  const productTagsRowExists =
    product?.betterBuy?.product?.productTags?.length > 0;

  if (productTagsRowExists) {
    height += UsfBetterBuyCardHeights.touchAdditionalRowHeight;
  }
  if (
    product?.betterBuy?.annualSavings > 0 &&
    (product?.betterBuy?.savingsPerCase > 0 || product?.betterBuy?.savings > 0)
  ) {
    height += UsfBetterBuyCardHeights.savingsBoxHeight;
  } else if (
    product?.betterBuy?.savings > 0 ||
    product?.betterBuy?.savingsPerCase > 0
  ) {
    height += UsfBetterBuyCardHeights.savingsBoxWithOneLineHeight;
  }
  return height;
};

export const calculateNoQtyBoxWithLineNumberProductHeight = (
  platformType: PlatformEnum,
  product: ProductRow,
): number => {
  if (platformType === PlatformEnum.desktop) {
    return calculateDesktopNoQtyBoxWithLineNumberProductHeight(product);
  } else if (platformType === PlatformEnum.tablet) {
    return calculateTabletNoQtyBoxWithLineNumberProductHeight(product);
  } else if (platformType === PlatformEnum.mobile) {
    return calculateMobileNoQtyBoxWithLineNumberProductHeight(product);
  }
};

export const calculateDesktopNoQtyBoxWithLineNumberProductHeight = (
  product: ProductRow,
): number => {
  let height = 0;
  height += UsfNoQtyBoxWithLineNumberHeights.baseDesktop;
  if (product?.productTags?.length > 0) {
    height += UsfNoQtyBoxWithLineNumberHeights.tagsDesktop;
  }
  if (product?.drawerMsg?.message) {
    height += UsfNoQtyBoxWithLineNumberHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfNoQtyBoxWithLineNumberHeights.warningBorderHeight;
  }
  if (product?.selected) {
    height += UsfNoQtyBoxWithLineNumberHeights.isChecked;
  }
  return height;
};

export const calculateTabletNoQtyBoxWithLineNumberProductHeight = (
  product: ProductRow,
): number => {
  let height = 0;
  if (product?.notFound) {
    return UsfNoQtyBoxWithLineNumberHeights.tabletNotFound;
  } else {
    height += UsfNoQtyBoxWithLineNumberHeights.baseTablet;
  }

  if (product?.productTags?.length > 0) {
    height += UsfNoQtyBoxWithLineNumberHeights.tagsTablet;
  }
  if (product?.drawerMsg?.message) {
    height += UsfNoQtyBoxWithLineNumberHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfNoQtyBoxWithLineNumberHeights.warningBorderHeight;
  }
  if (product?.selected) {
    height += UsfNoQtyBoxWithLineNumberHeights.isChecked;
  }
  return height;
};

const calculateMobileNoQtyBoxWithLineNumberProductHeight = (
  product: ProductRow,
): number => {
  let height = 0;
  if (product?.notFound) {
    return UsfNoQtyBoxWithLineNumberHeights.mobileNotFound;
  } else {
    height += UsfNoQtyBoxWithLineNumberHeights.baseMobile;
  }
  if (product?.productTags?.length > 0) {
    height += UsfNoQtyBoxWithLineNumberHeights.tagsMobile;
  }
  if (product?.drawerMsg?.message) {
    height += UsfNoQtyBoxWithLineNumberHeights.drawerHeight;
  }
  if (product?.drawerMsg?.isWarning) {
    height += UsfNoQtyBoxWithLineNumberHeights.warningBorderHeight;
  }
  if (product?.selected) {
    height += UsfNoQtyBoxWithLineNumberHeights.isChecked;
  }
  if (!!product?.promotionDetails) {
    height += UsfNoQtyBoxWithLineNumberHeights.promotionsMobile;
  }
  return height;
};
