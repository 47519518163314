<ion-header
  class="ion-no-border"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-header-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="platform === platformRef.desktop"
        id="close-modal-btn"
        data-cy="close-verify-products-modal-icon"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      [attr.data-cy]="'verify-products-title'"
      class="title-bold ion-text-wrap"
    >
      {{ this.title }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  scrollY="false"
  [ngClass]="
    platform === platformRef.desktop
      ? 'ion-content-modal-desktop'
      : platform === platformRef.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
  "
>
  <ion-label
    [attr.data-cy]="'verify-products-sub-title'"
    class="sub-title ion-text-wrap"
  >
    {{ 'i18n.lists.verifyProductsModal.subTitle' | translate }}
  </ion-label>
  <div class="container">
    <div class="scroll">
      <ion-item
        *ngFor="let item of selectedLists; let i = index"
        [class.header]="item?.type === MultiSelectItemsRef.selectableHeader"
      >
        <div
          *ngIf="item?.type !== MultiSelectItemsRef.selectableHeader"
          class="number"
        >
          {{ item.subLabel }}
        </div>
        <div [class.bold]="item?.type !== MultiSelectItemsRef.selectableHeader">
          {{ item?.label }}
        </div>
      </ion-item>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        [attr.data-cy]="'verify-products-cancel-button'"
        [ngClass]="
          platform === platformRef.mobile
            ? 'usf-outline-green-mobile-modal-button'
            : 'usf-outline-green-dsktp-tblt-modal-button'
        "
        (click)="goBack()"
      >
        {{ 'i18n.common.goBack' | translate }}
      </ion-button>

      <ion-button
        [attr.data-cy]="'verify-products-next-button'"
        [ngClass]="
          platform === platformRef.mobile
            ? 'usf-fill-green-mobile-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="submit()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
